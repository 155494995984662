import React, { useState, useEffect } from "react";
import { Box, LinearProgress, Typography, Button, Chip } from "@mui/material";
import { styled } from "@mui/system";
import { useDispatch } from "react-redux";
import { showSnackbar } from "../../redux/snackbar/actions";
import userManual from "../../assets/pdf/LDS_TAGCS-PO00010_User_Manual_v1.pdf";

const getColor = (value) => {
  if (value <= 50) {
    return "green";
  } else if (value <= 80) {
    return "orange";
  } else {
    return "red";
  }
};

const RoundedLinearProgress = styled(LinearProgress)(({ theme, value }) => ({
  height: 6,
  borderRadius: 5,
  "& .MuiLinearProgress-bar": {
    borderRadius: 5,
    backgroundColor: getColor(value),
  },
}));

export default function LinearBuffer() {
  const [progress, setProgress] = useState(0);
  const [storage, setStorage] = useState(0);
  const dispatch = useDispatch();
  useEffect(() => {
    setStorage(50);
  }, []);
  const handleOpenPdf = () => {
    window.open(userManual);
  };

  const progressRef = React.useRef(() => {});
  useEffect(() => {
    progressRef.current = () => {
      if (progress >= 50) {
        setProgress(storage);
      } else {
        const diff = Math.random() * 10;
        setProgress((prevProgress) => prevProgress + diff);
      }
    };
  });

  useEffect(() => {
    const timer = setInterval(() => {
      progressRef.current();
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const handleStorage = () => {
    dispatch(
      showSnackbar({
        message: "Option under maintenance",
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
        variant: "warning",
      })
    );
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ display: "flex", alignItems: "center", color: "#192c54" }}>
        <Typography variant="subtitle2">Storage Details</Typography>
        {/* <Chip
          onClick={handleOpenPdf}
          label="User Manual"
          variant="outlined"
          size="small"
          sx={{ ml: "auto" }}
          color="primary"
        /> */}
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <RoundedLinearProgress variant="determinate" value={progress} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="textSecondary">{`${Math.round(
            progress
          )}%`}</Typography>
        </Box>
      </Box>
      <Box>
        <Typography variant="subtitle2" color="textSecondary">
          10.00GB of 20.00GB used
        </Typography>
        <Button
          variant="outlined"
          color="error"
          size="small"
          onClick={handleStorage}
        >
          Buy Storage
        </Button>
      </Box>
    </Box>
  );
}
