const BASE_URL = "https://api-review.tagcs.net/lds-po00010/api/v1";
const IMG_URL = `https://api-review.tagcs.net/lds-po00010/uploads/`;

export const API_URL = {
  LOGIN: `${BASE_URL}/login`,
  FORGOT: `${BASE_URL}/forgotPassword`,
  LOGOUT: `${BASE_URL}/logout`,
  VERIFY_OTP: `${BASE_URL}/verifyOtp`,
  RESET_PASSWORD: `${BASE_URL}/resetPassword`,
  CHANGE_PASSWORD: `${BASE_URL}/resetPassword/loggedIn`,
  CUSTOMER_CREATE: `${BASE_URL}/signup/customer`,
  CUSTOMER_GETALL: `${BASE_URL}/customer`,
  CUSTOMER_GET: `${BASE_URL}/customer`,
  CUSTOMER_LOCATION: `${BASE_URL}/customerLocation`,
  UPDATE_CUSTOMER: `${BASE_URL}/customer`,
  ORDER_DATE: `${BASE_URL}/order/shop/latest`,
  ORDER_GETALL: `${BASE_URL}/order`,
  ORDER_GET: `${BASE_URL}/order`,
  ORDER_CREATE: `${BASE_URL}/order`,
  ORDER_UPDATE: `${BASE_URL}/order`,
  ORDER_HISTORY: `${BASE_URL}/order/filter`,
  ORDER_COPY: `${BASE_URL}/order/copy`,
  DRESSTYPE_GETALL: `${BASE_URL}/dressType`,
  DRESSTYPE_CREATE: `${BASE_URL}/dressType`,
  MASTER_GETALL: `${BASE_URL}/cuttingMaster`,
  MASTER_CREATE: `${BASE_URL}/cuttingMaster`,
  TAILOR_GETALL: `${BASE_URL}/tailor`,
  TAILOR_CREATE: `${BASE_URL}/tailor`,
  ROUGH_IMAGE: `${BASE_URL}/upload/single`,
  INVOICE_CREATE: `${BASE_URL}/invoice`,
  INVOICE_GETALL: `${BASE_URL}/invoice`,
  INVOICE_GET: `${BASE_URL}/invoice`,
  INVOICE_GENERATED: `${BASE_URL}/invoice/filter`,
  INVOICE_GENERATED_COPY: `${BASE_URL}/invoice/copy`,
  FILTER_ORDER: `${BASE_URL}/order/filter`,
  FILTER_ORDER_FOLLOWUPS: `${BASE_URL}/order/filter/followups`,
  COLLECTION_REPORTS: `${BASE_URL}/order/payments`,
  LOGOUT: `${BASE_URL}/logout`,
};
export const ACTION_TYPES = {
  SHOW_SNACKBAR: "SHOW_SNACKBAR",
  HIDE_SNACKBAR: "HIDE_SNACKBAR",
  SHOW_LOADER: "SHOW_LOADER",
  HIDE_LOADER: "HIDE_LOADER",
  AUTH: "AUTH",
  PRINT_DATA: "PRINT_DATA",
};

export { IMG_URL };
