import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Modal,
  TextField,
  IconButton,
  Autocomplete,
} from "@mui/material";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { showLoader, hideLoader } from "../../../redux/loader/actions/index";
import { showSnackbar } from "../../../redux/snackbar/actions/index";
import customerApi from "../../../services/customer";
import { logDOM } from "@testing-library/react";
import _ from "lodash";
import Cookies from "js-cookie";

const useStyles = makeStyles((theme) => ({
  btn: {
    backgroundColor: "#f06666 !important",
  },
}));

const Customer_Registration = ({
  setOpen,
  open,
  getAllCustomersList,
  setGetCustomerList,
  setCustomerData,
  setCustomerId,
  customerId,
  customerData,
  mode,
  setMode,
  inputValue,
  mobileNumber,
  allMobileNumber,
  handleMobileNumberChange,
}) => {
  const [locationList, setLocationList] = useState([]);
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleClose = () => {
    setOpen(false);
    formik?.resetForm();
    setCustomerId && setCustomerId(null);
    setCustomerData && setCustomerData(null);
    setMode && setMode(null);
  };
  const savedShopId = Cookies.get("shop_id");
  const savedCreatedBy = Cookies.get("created_by");
  const handleSubmit = (formData) => {
    const user = { user: { mobile_number: formData.mobilenumber } };
    const data = {
      name: formData.customername,
      email: formData.email,
      mobile_number: formData.mobilenumber,
      shop_id: savedShopId,
      role_id: "2",
      ref_name: formData.refname,
      alt_mobile_number: formData.alternativemobilenumber,
      location: formData.location.name,
      created_by: savedCreatedBy,
    };

    dispatch(showLoader());

    const onSuccess = (res) => {
      dispatch(hideLoader());
      if (res?.data?.status === true) {
        dispatch(
          showSnackbar({
            message: res?.data?.msg || "Info updated successfully",
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "success",
          })
        );
      }
      if (mode === "create") {
        handleMobileNumberChange(null, user);
        formik.resetForm();
        setOpen(false);
        setCustomerId(null);
        setCustomerData(null);
        setMode(null);
        allMobileNumber();
      } else if (mode === "new") {
        formik.resetForm();
        setOpen(false);
        setCustomerId();
        setCustomerData(null);
        setMode(null);
        getAllCustomersList();
        getAllLocationList();
      } else {
        formik.resetForm();
        setOpen(false);
        setCustomerId();
        setCustomerData(null);
        setMode(null);
        getAllCustomersList();
        getAllLocationList();
      }
    };

    const onFailure = (err) => {
      dispatch(hideLoader());
      dispatch(
        showSnackbar({
          message: err?.response?.data?.msg || "Failed to fetch data Creation",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "error",
        })
      );
    };

    if (mode === "edit") {
      customerApi
        .customerUpdate(customerId, data)
        .then(onSuccess)
        .catch(onFailure);
    } else {
      customerApi.customerCreate(data).then(onSuccess, onFailure);
    }
  };
  const getCustomer = () => {
    if (customerId && open && mode === "edit") {
      dispatch(showLoader());
      customerApi
        .getCustomer(customerId)
        .then((res) => {
          dispatch(hideLoader());
          setCustomerData(res?.data?.data);
        })
        .catch((err) => {
          dispatch(hideLoader());
          dispatch(
            showSnackbar({
              message:
                err?.response?.data?.msg || "Failed to fetch data customerList",
              autoHideDuration: 3000,
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "error",
            })
          );
        });
    }
  };

  useEffect(() => {
    getCustomer();
  }, [mode, customerId, open]);

  const getAllLocationList = useCallback(() => {
    dispatch(showLoader());
    customerApi
      .getAllLocation()
      .then((res) => {
        dispatch(hideLoader());
        const locationData =
          res?.data?.data?.map((item) => ({
            label: item.name,
          })) || [];
        setLocationList(res?.data?.data);
      })
      .catch((err) => {
        dispatch(hideLoader());
        dispatch(
          showSnackbar({
            message:
              err?.response?.data?.msg || "Failed to fetch data location",
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
          })
        );
      });
  }, []);

  useEffect(() => {
    if (open) {
      getAllLocationList();
    }
  }, [getAllLocationList, open]);

  useEffect(() => {
    if (mode === "edit" && customerData) {
      formik.setFieldValue("customername", customerData.user?.name || "");
      formik.setFieldValue("refname", customerData.ref_name || "");
      formik.setFieldValue(
        "mobilenumber",
        customerData.user?.mobile_number || ""
      );
      formik.setFieldValue(
        "alternativemobilenumber",
        customerData.alt_mobile_number || ""
      );
      formik.setFieldValue("email", customerData.user?.email || "");

      const foundLocation =
        locationList.find(
          (loc) =>
            loc.name?.toLowerCase() === customerData?.location?.toLowerCase()
        ) || null;

      formik.setFieldValue("location", foundLocation ? foundLocation : null);
    }
  }, [customerData, mode, locationList]);

  const handleLocationOnChange = (event, newValue) => {
    if (newValue && newValue.inputValue) {
      const newOption = {
        name: newValue.inputValue,
        id: newValue.inputValue.toLowerCase().replace(/\s+/g, "-"),
      };
      formik.setFieldValue("location", newOption);
    } else {
      formik.setFieldValue("location", newValue ? newValue : "");
    }
  };

  const formik = useFormik({
    initialValues: {
      customername: "",
      refname: "",
      mobilenumber: mobileNumber ? mobileNumber?.user?.mobile_number : "",
      alternativemobilenumber: "",
      email: "",
      location: null,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      customername: Yup.string()
        .min(3, "Customer Name at least 3 characters")
        .max(25, "Must be 25 characters or less")
        .matches(
          /^[A-Za-z\s]+$/,
          "Customer Name must only contain letters and spaces"
        )
        .required("Customer Name Required"),
      refname: Yup.string()
        .min(3, "Ref Name at least 3 characters")
        .max(25, "Must be 25 characters or less")
        .matches(
          /^[A-Za-z\s]+$/,
          "Customer Name must only contain letters and spaces"
        ),
      mobilenumber: Yup.string()
        .matches(/^[0-9]{10}$/, "Mobile Number must be 10 digits")
        .required("Mobile Number Required"),
      alternativemobilenumber: Yup.string().matches(
        /^[0-9]{10}$/,
        "Alternative Mobile Number must be 10 digits"
      ),
      email: Yup.string().email("Invalid email"),
      location: Yup.object().required("Location Required"),
    }),
    onSubmit: handleSubmit,
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    maxHeight: "90vh",
    overflowY: "auto",
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableBackdropClick={true}
        BackdropProps={{
          onClick: (event) => {
            event.stopPropagation();
          },
        }}
      >
        <Box sx={{ ...style, display: "flex", flexDirection: "column", mb: 2 }}>
          <Grid container alignItems="center">
            <Grid item xs={6}>
              <Typography variant="h6" component="h2" gutterBottom>
                Customer Registration
              </Typography>
            </Grid>
            <Grid item xs={6} container justifyContent="flex-end">
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Box mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} lg={4}>
                <TextField
                  fullWidth
                  required
                  label="Customer Name"
                  variant="outlined"
                  id="customername"
                  name="customername"
                  placeholder="Enter The Customer Name"
                  onChange={formik.handleChange}
                  onBlur={formik?.handleBlur}
                  error={
                    formik?.touched?.customername &&
                    Boolean(formik?.errors?.customername)
                  }
                  helperText={
                    formik?.touched?.customername &&
                    formik?.errors?.customername
                  }
                  value={formik?.values?.customername}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <TextField
                  fullWidth
                  id="refname"
                  name="refname"
                  label="Reference Name"
                  placeholder="Enter Reference Name"
                  variant="outlined"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.refname && Boolean(formik.errors.refname)
                  }
                  helperText={formik.touched.refname && formik.errors.refname}
                  value={formik.values.refname}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <TextField
                  fullWidth
                  required
                  id="mobilenumber"
                  name="mobilenumber"
                  label="Mobile Number"
                  placeholder="Enter Mobile Number"
                  variant="outlined"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.mobilenumber &&
                    Boolean(formik.errors.mobilenumber)
                  }
                  helperText={
                    formik.touched.mobilenumber && formik.errors.mobilenumber
                  }
                  value={formik.values.mobilenumber}
                />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <TextField
                  fullWidth
                  id="alternativemobilenumber"
                  name="alternativemobilenumber"
                  label="Alternative Mobile Number"
                  placeholder="Enter Alternative Mobile Number"
                  variant="outlined"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.alternativemobilenumber &&
                    Boolean(formik.errors.alternativemobilenumber)
                  }
                  helperText={
                    formik.touched.alternativemobilenumber &&
                    formik.errors.alternativemobilenumber
                  }
                  value={formik.values.alternativemobilenumber}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <TextField
                  fullWidth
                  id="email"
                  name="email"
                  label="Email"
                  placeholder="Enter Email"
                  variant="outlined"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  value={formik.values.email}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <Autocomplete
                  fullWidth
                  options={locationList}
                  id="location"
                  name="location"
                  getOptionLabel={(option) =>
                    typeof option === "string" ? option : option.name
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={formik.values.location}
                  onChange={handleLocationOnChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Location"
                      required
                      variant="outlined"
                      placeholder="Select or Create Location"
                      error={
                        formik.touched.location &&
                        Boolean(formik.errors.location)
                      }
                      helperText={
                        formik.touched.location && formik.errors.location
                      }
                    />
                  )}
                  filterOptions={(options, params) => {
                    const filtered = options.filter((option) =>
                      option.name
                        .toLowerCase()
                        .includes(params.inputValue.toLowerCase())
                    );

                    if (params.inputValue !== "") {
                      filtered.push({
                        inputValue: params.inputValue,
                        name: `Add "${params.inputValue}"`,
                      });
                    }

                    return filtered;
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  freeSolo
                />
              </Grid>
            </Grid>
            <Box mt={2} textAlign="right">
              <Button
                variant="contained"
                className={classes.btn}
                onClick={formik?.handleSubmit}
                size="medium"
              >
                <DoneOutlinedIcon />
                {mode === "edit" ? "Update" : "Submit"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default Customer_Registration;
