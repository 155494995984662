import axios from "axios";
import { API_URL } from "../../constants";
import _ from "lodash";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  customerCreate: (data) => {
    return axios.post(API_URL.CUSTOMER_CREATE, data, {
      headers: {
        "Content-Type": "application/json",
        isAuthRequired: true,
      },
    });
  },
  getAllCustomer: (page, pageSize, sortBy, sort, searchParams) => {
    const filteredParams = _.pickBy(
      searchParams,
      (value) => !_.isNil(value) && value !== false && value !== ""
    );
    return axios.get(API_URL.CUSTOMER_GETALL, {
      headers: {
        "Content-Type": "application/json",
        isAuthRequired: true,
      },
      params: {
        filter: JSON.stringify(filteredParams),
        filtering: true,
        page: page,
        pageSize: pageSize,
        sortBy: sortBy,
        sortOrder: sort,
      },
    });
  },
  getAllCustomerExport: (searchParams, rowCount) => {
    const filteredParams = _.pickBy(
      searchParams,
      (value) => !_.isNil(value) && value !== false && value !== ""
    );
    return axios.get(API_URL.CUSTOMER_GETALL, {
      headers: {
        "Content-Type": "application/json",
        isAuthRequired: true,
      },
      params: {
        filter: JSON.stringify(filteredParams),
        filtering: true,
        pageSize: rowCount,
      },
    });
  },
  getAllMobileNumber: () => {
    return axios.get(API_URL.CUSTOMER_GETALL, {
      headers: {
        "Content-Type": "application/json",
        isAuthRequired: true,
      },
      params: {
        mobileList: true,
      },
    });
  },
  getCustomerName: (mobileNumber) => {
    return axios.get(API_URL.CUSTOMER_GETALL, {
      headers: {
        "Content-Type": "application/json",
        isAuthRequired: true,
      },
      params: {
        byCustomerMobile: true,
        mobile_number: mobileNumber,
      },
    });
  },
  getCustomer: (id) => {
    return axios.get(`${API_URL.CUSTOMER_GET}/${id}`, {
      headers: {
        "Content-Type": "application/json",
        isAuthRequired: true,
      },
    });
  },
  getAllLocation: () => {
    return axios.get(API_URL.CUSTOMER_LOCATION, {
      headers: {
        "Content-Type": "application/json",
        isAuthRequired: true,
      },
    });
  },
  customerUpdate: (id, data) => {
    return axios.patch(`${API_URL.UPDATE_CUSTOMER}/${id}`, data, {
      headers: {
        "Content-Type": "application/json",
        isAuthRequired: true,
      },
    });
  },
};
