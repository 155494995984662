import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TableHead,
  Typography,
  Grid,
  Box,
  Divider,
  Button,
} from "@mui/material";

import Logo from "../../../assets/img/home.png";
import QRCode from "../../../assets/img/qr2.png";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import { useParams } from "react-router-dom";
import { styled } from "@mui/system";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import invoiceApi from "../../../services/invoice";

const PrintButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#f06666",
  marginBottom: theme.spacing(2),
  "@media print": {
    display: "none",
  },
  "&:hover": {
    backgroundColor: "#f06666",
    boxShadow: "none",
  },
}));
const CustomerPrint = () => {
  const [printData, setPrintData] = useState(null);
  const { orderId } = useParams();
  const getInvoice = () => {
    const onSuccess = (res) => {
      setPrintData(res?.data?.data);
    };
    const onFailure = () => {};
    invoiceApi.generatedInvoiceCopy(orderId).then(onSuccess).catch(onFailure);
  };

  useEffect(() => {
    getInvoice();
  }, [orderId]);
  console.log("order", printData);

  const formatDate = (dateStr) => {
    if (!dateStr) return "";
    const dateObj = new Date(dateStr);
    const formattedDate = `${dateObj.getDate().toString().padStart(2, "0")}-${(
      dateObj.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${dateObj.getFullYear()}`;
    return formattedDate;
  };
  const particulars =
    printData && printData.particulars ? printData.particulars : [];

  const handlePrint = () => {
    window.print();
  };
  const formatTime = (dateString) => {
    const date = new Date(dateString);
    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });
    return formattedTime;
  };

  return (
    <div>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <PrintButton
          variant="contained"
          size="medium"
          onClick={handlePrint}
          sx={{ display: "flex", alignItems: "center", mx: 1 }}
        >
          <LocalPrintshopOutlinedIcon sx={{ marginRight: 1 }} />
          Print
        </PrintButton>
      </Box>
      <Grid container>
        <Grid item sm={6}>
          <Typography variant="h6">Tailoring Automation System</Typography>
          <Typography sx={{ fontSize: "11px" }}>
          No 40C, 1st Floor, Murugan Koil Street,
            <Typography sx={{ fontSize: "11px" }}>
              {" "}
              Vivekananda Nagar Main Rd,near Senthil Nagar, Kolathur,
            </Typography>
          </Typography>
          <Typography sx={{ fontSize: "11px" }}>
          Chennai, TamilNadu 600099,
          </Typography>
          <Typography sx={{ fontSize: "11px" }}>
          Mobile: +91 98431 66444
          </Typography>
          <Typography sx={{ fontSize: "11px" }}>
          E-Mail: istorecare@tagcs.in
          </Typography>
          <Typography sx={{ fontSize: "11px" }}>
            GST NO: 29GGGGG1314R9Z6
          </Typography>
          <Typography sx={{ fontSize: "11px" }}>
            State: <span>Tamil Nadu</span>, State Code: <span>33</span>
          </Typography>
        </Grid>
        <Grid item sm={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <img
            src={Logo}
            alt="Logo"
            style={{
              width: "140px",
              marginRight: "20px",
              marginTop: "20px",
              // height:"83px"
            }}
          />
        </Grid>
      </Grid>
      <Divider fullwidth sx={{}} />
      <Typography variant="subtitle1" sx={{ textAlign: "center" }}>
        TAX INVOICE
      </Typography>
      <Divider fullwidth sx={{}} />

      <Grid container spacing={2}>
        <Grid
          item
          xs={4}
          sm={6}
          md={6}
          style={{
            borderRight: "1px solid #ccc",
            minHeight: "68px",
            padding: "8px",
            marginTop: "15px",
            position: "relative",
          }}
        >
          {" "}
          <Grid item sm={6} md={6}>
            <Grid container>
              <Grid item sm={6} md={7}>
                <Typography gutterBottom sx={{ fontSize: "12px" }}>
                  <strong>Name:</strong>
                </Typography>
              </Grid>
              <Grid item sm={6} md={5}>
                <Typography sx={{ fontSize: "12px" }}>
                  {printData?.orderById?.customer?.user?.name}{" "}
                  {printData?.orderById?.customer?.ref_name &&
                    `(${printData?.orderById?.customer?.ref_name})`}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item sm={6} md={6}>
                <Typography gutterBottom sx={{ fontSize: "12px" }}>
                  <strong>Mobile Number:</strong>
                </Typography>
              </Grid>
              <Grid item sm={6} md={6}>
                <Typography sx={{ fontSize: "12px" }}>
                  {printData?.orderById?.customer?.user?.mobile_number}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8} sm={6} md={6}>
          <Grid container>
            <Grid item sm={6}>
              <Grid container>
                <Grid item sm={6}>
                  <Typography gutterBottom sx={{ fontSize: "12px" }}>
                    <strong>Invoice ID:</strong>
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography sx={{ fontSize: "12px" }}>
                    {printData?.invoice_id}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={6}>
              <Grid container>
                <Grid item sm={6}>
                  <Typography gutterBottom sx={{ fontSize: "12px" }}>
                    <strong>Invoice Date:</strong>
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography sx={{ fontSize: "12px" }}>
                    {formatDate(printData?.created_at)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={6}>
              <Grid container>
                <Grid item sm={6}>
                  <Typography gutterBottom sx={{ fontSize: "12px" }}>
                    <strong>Order Id:</strong>
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography sx={{ fontSize: "12px" }}>
                    {printData?.orderById?.order_id}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={6}>
              <Grid container>
                <Grid item sm={6}>
                  <Typography gutterBottom sx={{ fontSize: "12px" }}>
                    <strong>Customer Id:</strong>
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography sx={{ fontSize: "12px" }}>
                    {printData?.orderById?.customer?.cust_id}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={6}>
              <Grid container>
                <Grid item sm={6}>
                  <Typography gutterBottom sx={{ fontSize: "12px" }}>
                    <strong> Taken Time:</strong>
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography sx={{ fontSize: "12px" }}>
                    {formatTime(printData?.orderById?.delivery_date)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={6}>
              <Grid container>
                <Grid item sm={6}>
                  <Typography gutterBottom sx={{ fontSize: "12px" }}>
                    <strong> Delivery Date:</strong>
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography sx={{ fontSize: "12px" }}>
                    {printData?.orderById?.is_second_delivery_date_req !== true
                      ? formatDate(printData?.orderById?.delivery_date)
                      : formatDate(printData?.orderById?.second_delivery_date)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Divider fullwidth sx={{}} />
      <TableContainer component={Paper} sx={{ mt: 1 }}>
        <Table sx={{ minWidth: 550 }}>
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={{ py: 1 }}>
                S.No
              </TableCell>
              <TableCell align="center" sx={{ py: 1 }}>
                Particulars
              </TableCell>
              <TableCell align="center" sx={{ py: 1 }}>
                Qty
              </TableCell>
              <TableCell align="center" sx={{ py: 1 }}>
                Amount
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {particulars.map((row) => (
              <TableRow key={row.sno}>
                <TableCell align="center" sx={{ py: 0.5 }}>
                  {row.id}
                </TableCell>
                <TableCell align="center" sx={{ py: 0.5 }}>
                  {row.particulars}
                </TableCell>
                <TableCell align="center" sx={{ py: 0.5 }}>
                  {row.qty}
                </TableCell>
                <TableCell align="center" sx={{ py: 0.5 }}>
                  {row.amount}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ mt: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} lg={6} sx={{ marginLeft: "auto" }}>
            <Grid item xs={12}>
              <Grid container>
                <Grid sm={6}>
                  <Typography>
                    <strong>Sub Total:</strong>
                  </Typography>
                </Grid>
                <Grid sm={6}>
                  <Typography sx={{ textAlign: "right" }}>
                    {" "}
                    ₹ {printData?.sub_total}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid sm={6}>
                  <Typography>
                    <strong>CGST @ 2.5%</strong>
                  </Typography>
                </Grid>
                <Grid sm={6}>
                  <Typography sx={{ textAlign: "right" }}>
                    ₹ {printData?.c_gst}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid sm={6}>
                  <Typography>
                    <strong>SGST @ 2.5%</strong>
                  </Typography>
                </Grid>
                <Grid sm={6}>
                  <Typography sx={{ textAlign: "right" }}>
                    ₹ {printData?.s_gst}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid sm={6}>
                  <Typography>
                    <strong>Total Amount:</strong>
                  </Typography>
                </Grid>
                <Grid sm={6}>
                  <Typography sx={{ textAlign: "right" }}>
                    ₹ {printData?.net_amount}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Divider fullwidth />

      <Typography variant="subtitle1" sx={{ textAlign: "center" }}>
        Payment Details
      </Typography>
      <Divider fullwidth />

      <Box
        sx={{
          border: "1px solid #a0a6a8",
          borderRadius: "5px",
          my: 1,
          overflow: "hidden",
        }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={6}
            lg={4}
            sx={{ textAlign: "center", borderRight: "1px solid #000" }}
          >
            <Typography sx={{ fontWeight: "bold", fontSize: "15px", mt: 2 }}>
              BANK DETAILS
            </Typography>
            <Typography sx={{ fontWeight: "bold", fontSize: "13px" }}>
            Tailoring Automation System
            </Typography>
            <Typography sx={{ fontWeight: "bold", fontSize: "13px" }}>
            A/c : 69874521365448
            </Typography>
            <Typography sx={{ fontWeight: "bold", fontSize: "13px" }}>
            IFSC : UTIB8754216
            </Typography>
            <Typography sx={{ fontWeight: "bold", fontSize: "13px" }}>
            Branch : Kolathur
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} lg={6} sx={{ textAlign: "center" }}>
            <Typography
              sx={{
                fontWeight: "bold",
                textAlign: "center",
                fontSize: "15px",
              }}
            >
              SCAN TO PAY
            </Typography>
            <img
              src={QRCode}
              alt="QR Code"
              style={{ width: "100px", display: "block", margin: "0 auto" }}
            />
            <Typography
              sx={{
                fontWeight: "bold",
                textAlign: "center",
                fontSize: "12px",
              }}
            >
             UPI ID: tag097654-2@okaxis
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          border: "1px solid #a0a6a8",
          px: 1,
          borderRadius: "5px",
          overflow: "hidden",
        }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={6}
            lg={4}
            sx={{ borderRight: "1px solid #000" }}
          >
            <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
              Terms & Conditions
            </Typography>
            <Typography sx={{ mt: 1, fontWeight: "bold", fontSize: "11px" }}>
              1. No alterations will be accepted after 45 days from the delivery date.
            </Typography>
            <Typography sx={{ mt: 1, fontWeight: "bold", fontSize: "11px" }}>
              2. Order cancellation fee ₹ 500/- will apply for confirmed bookings.
            </Typography>
            <Typography sx={{ mt: 1, fontWeight: "bold", fontSize: "11px" }}>
              3. NO REFUNDS or EXCHANGES.
            </Typography>
            <Typography sx={{ mt: 1, fontWeight: "bold", fontSize: "11px" }}>
              4. Deliveries are available from Monday to Friday.
            </Typography>
            <Typography sx={{ mt: 1, fontWeight: "bold", fontSize: "11px" }}>
              5. Delivery Timings: 11am to 7pm.
            </Typography>
            <Typography sx={{ mt: 1, fontWeight: "bold", fontSize: "11px" }}>
              6.SHOP CLOSED ON SUNDAYS AND PUBLIC HOLIDAYS.
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            lg={6}
            sx={{ textAlign: "right", marginTop: "auto" }}
          >
            <Typography sx={{ textAlign: "right" }}>
              Authorised Signatory
            </Typography>
            <Typography sx={{ textAlign: "right" }}>
            Tailoring Automation System
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Typography variant="subtitle1" sx={{ textAlign: "center" }}>
        Thanks for your order, Welcome soon !
      </Typography>
    </div>
  );
};

export default CustomerPrint;
