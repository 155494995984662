import React from "react";
import { Card, CardActions, CardContent } from "@mui/material";
import BannerImage from "../../assets/img/banner3.png";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  btn: {
    backgroundColor: "#f06666 !important",
  },
  cardRadius: {
    borderRadius: "0 15px 15px 0 !important",
    borderLeft: "3px solid #f06666",
  },
  imgCenter: {
    display: "block",
    margin: "0 auto",
    width: "100%",
    maxWidth: "350px",
  },
  '@media (max-width: 320px)': {
    imgCenter: {
      width: "100%",
      maxWidth: "320px",
    },
  },
}));

const Home = () => {
  const classes = useStyles();
  return (
    <div>
      <Card elevation={5} fullWidth className={classes.cardRadius}>
        <CardContent>
          <img
            src={BannerImage}
            alt="BannerImage"
            className={classes.imgCenter}
          />
        </CardContent>
        <CardActions></CardActions>
      </Card>
    </div>
  );
};

export default Home;
