import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Divider,
  Box,
  Button,
  Typography,
  Grid,
  Modal,
  TextField,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  FormControl,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  FormControlLabel,
} from "@mui/material";
import BreakUpModal from "./breakupmodal/index";
import AttachModal from "./attachmodal/index";
import AttachCopy from "./attachcopy/index";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { makeStyles } from "@material-ui/core";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import { useFormik } from "formik";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import * as Yup from "yup";
import CloseIcon from "@mui/icons-material/Close";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Popup from "../../../component/tooltip/index";
import CustomerCreate from "../../customer/create/index";
import OrderHistory from "../orderhistory/index";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import customerApi from "../../../services/customer";
import orderApi from "../../../services/order";
import { useDispatch } from "react-redux";
import { showLoader, hideLoader } from "../../../redux/loader/actions/index";
import { showSnackbar } from "../../../redux/snackbar/actions/index";
import moment from "moment";
import _ from "lodash";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Whatsapp from "../whatsapp/index";
import Cookies from "js-cookie";

const useStyles = makeStyles((theme) => ({
  btn: {
    backgroundColor: "#f06666 !important",
  },
  icon: {
    fontSize: "17px !important",
    position: "relative",
    top: "3px",
    cursor: "pointer",
  },
  cardRadius: {
    borderRadius: "0 15px 15px 0 !important",
    borderLeft: "3px solid #f06666",
  },
}));
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  maxHeight: "90vh",
  overflowY: "auto",
};

const Create = ({
  setOpen,
  open,
  setMode,
  mode,
  getAllOrderList,
  customerViewId,
  setCustomerViewId,
  orderParticularData,
}) => {
  const classes = useStyles();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    maxHeight: "90vh",
    overflowY: "auto",
  };

  const divider = {
    mt: 2,
    py: 0,
    width: "98%",
    borderRadius: 2,
    border: "1px solid",
    borderColor: "divider",
    backgroundColor: "background.paper",
  };

  const size1 = [
    { id: 1, title: "Bl", name: "blouse_bi" },
    { id: 2, title: "Sh", name: "blouse_sh" },
    { id: 3, title: "Sl", name: "blouse_si" },
    { id: 4, title: "R", name: "blouse_r" },
    { id: 5, title: "Arm", name: "blouse_arm" },
    { id: 6, title: "Up", name: "blouse_up" },
    { id: 7, title: "Ch", name: "blouse_ch" },
  ];
  const size2 = [
    { id: 1, title: "Yoke", name: "blouse_yoke" },
    { id: 2, title: "Waist", name: "blouse_waist" },
    { id: 3, title: "Bust", name: "blouse_bust" },
    { id: 4, title: "F", name: "blouse_f" },
    { id: 5, title: "B", name: "blouse_b" },
    { id: 7, title: "P2P", name: "blouse_p2p" },
    { id: 6, title: "Cut", name: "blouse_cut" },
  ];
  const size3 = [
    { id: 1, title: "Toplen", name: "set_toplen" },
    { id: 2, title: "Sh", name: "set_sh" },
    { id: 3, title: "Sl", name: "set_si" },
    { id: 4, title: "R", name: "set_r" },
    { id: 5, title: "Arm", name: "set_arm" },
    { id: 6, title: "Up", name: "set_up" },
    { id: 7, title: "Ch", name: "set_ch" },
  ];
  const size4 = [
    { id: 1, title: "Waist", name: "set_waist" },
    { id: 2, title: "Seat", name: "set_seat" },
    { id: 3, title: "F", name: "set_f" },
    { id: 4, title: "B", name: "set_b" },
  ];
  const size5 = [
    { id: 1, title: "Kneelen", name: "kneelen" },
    { id: 2, title: "Bol", name: "boi" },
    { id: 3, title: "Round", name: "round" },
    { id: 4, title: "K.Round", name: "kround" },
    { id: 5, title: "Thigh", name: "thigh" },
    { id: 6, title: "Waist", name: "waist" },
    { id: 7, title: "Seat", name: "seat" },
  ];

  const dispatch = useDispatch();

  const [mobileNumberOptions, setMobileNumberOptions] = useState(null);
  const [customerNameOptions, setCustomerNameOptions] = useState(null);
  // const [getCustomer, setGetCustomer] = useState({
  //   names: [],
  //   cusIds: [],
  //   refNames: [],
  //   id: [],
  //   location: [],
  // });
  const [selectedLabel, setSelectedLabel] = useState("");
  const [showCustomerInfo, setShowCustomerInfo] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedHistoryData, setSelectedHistoryData] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [isMobileInList, setIsMobileInList] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [showAddButton, setShowAddButton] = useState(false);
  const [createCustomer, setCreateCustomer] = useState(false);
  const [allDressType, setAllDressType] = useState("");
  const [allMaster, setAllMaster] = useState("");
  const [allTailor, setAllTailor] = useState("");
  const [breakupDetail, setBreakupDetail] = useState([]);
  const [orderDetails, setOrderDetails] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [selectedOrderDetail, setSelectedOrderDetail] = useState(null);
  const [roughDiagram, setRoughDiagram] = useState(null);
  const [showSecondDeliveryDate, setShowSecondDeliveryDate] = useState(false);
  const [editSecondDeliveryDate, setEditSecondDeliveryDate] = useState(false);
  const [openBreakupModal, setOpenBreakupModal] = useState(false);
  const [secondDeliveryDate, setSecondDeliveryDate] = useState(false);
  const [orderHistoryList, setOrderHistoryList] = useState([]);
  const [totalBillAmount, setTotalBillAmount] = useState();
  const [totalDiscountAmount, setTotalDiscountAmount] = useState();
  const [latestOrder, setLatestOrder] = useState(null);
  const [orderData, setOrderData] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [customerData, setCustomerData] = useState(null);
  const [firstDeliveryChosen, setFirstDeliveryChosen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [payments, setPayments] = useState([]);
  const [orderPrevPaymentDate, setOrderPrevPaymentDate] = useState(null);
  const [formReset, setFormReset] = useState(false);
  const [internalPrint, setInternalPrint] = useState(false);
  const [latestOrderDate, setLatestOrderDate] = useState(moment());
  const [latestPaymentDate, setLatestPaymentDate] = useState(moment());
  const [selectPaymentdate, setSelectPaymentDate] = useState();
  const [parsedPaymentDetails, setParsedPaymentDetails] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalPaidAmount, setTotalPaidAmount] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [hisopen, setHisopen] = useState(false);
  const [sumOfQty, setSumOfQty] = useState(0);
  const [sumOfAmount, setSumOfAmount] = useState(0);
  const [checkedItems, setCheckedItems] = useState({});
  const [shopId, setShopId] = useState();
  const [isCheckboxChecked, setIsCheckboxChecked] = React.useState(false);

  useEffect(() => {
    if (open) {
      setShopId(parseFloat(Cookies.get("shop_id")));
    }
  }, [open]);

  const formik = useFormik({
    initialValues: {
      mobileNumber: null,
      customerName: null,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      mobileNumber: Yup.object()
        .required("Mobile number is required")

        .nullable(),
      customerName: Yup.object().nullable(),
    }),

    onSubmit: () => {},
  });
  const handleFinalSubmit = (formData) => {
    const hasFirstDeliveryDate = orderDetails.some(
      (detail) => detail.deltype === "1st Delivery Date"
    );

    if (!hasFirstDeliveryDate) {
      dispatch(
        showSnackbar({
          message: "Please choose '1st Delivery Date' before saving.",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "warning",
        })
      );
      return;
    }
    if (!orderDetails) {
      if (
        formData?.values.deltype === "1st Delivery Date" &&
        formData?.values.deltype === "2nd Delviery Date"
      ) {
        setFirstDeliveryChosen(true);
        setShowSecondDeliveryDate(true);
      } else {
        dispatch(
          showSnackbar({
            message: "Please choose '1st Delivery Date' before saving.",
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "warning",
          })
        );
        setShowSecondDeliveryDate(false);
      }
      return;
    }

    dispatch(showLoader());
    const paymentDetailsArrLength = !_.isEmpty(latestOrder?.payment_details)
      ? latestOrder?.payment_details?.length
      : -1;
    const data = {
      cust_id: customerId,
      order_date:
        mode === "edit"
          ? moment(latestOrder?.order_date).format()
          : moment(formData.orddate).format(),
      particulars: orderDetails,
      blouse_bi: formData.blouse_bi,
      blouse_sh: formData.blouse_sh,
      blouse_si: formData.blouse_si,
      blouse_r: formData.blouse_r,
      blouse_arm: formData.blouse_arm,
      blouse_up: formData.blouse_up,
      blouse_ch: formData.blouse_ch,
      blouse_yoke: formData.blouse_yoke,
      blouse_waist: formData.blouse_waist,
      blouse_bust: formData.blouse_bust,
      blouse_f: formData.blouse_f,
      blouse_b: formData.blouse_b,
      blouse_p2p: formData.blouse_p2p,
      blouse_cut: formData.blouse_cut,
      set_toplen: formData.set_toplen,
      set_sh: formData.set_sh,
      set_si: formData.set_si,
      set_r: formData.set_r,
      set_arm: formData.set_arm,
      set_up: formData.set_up,
      set_ch: formData.set_ch,
      set_waist: formData.set_waist,
      set_seat: formData.set_seat,
      set_f: formData.set_f,
      set_b: formData.set_b,
      kneelen: formData.kneelen,
      boi: formData.boi,
      round: formData.round,
      kround: formData.kround,
      thigh: formData.thigh,
      waist: formData.waist,
      seat: formData.seat,
      notes: formData.notes,
      lining: formData.lining,
      quantity: formData.quantity,
      bill_amount: parseFloat(formData.bill_amount),
      advance: parseFloat(formData.advance),
      payment_mode: formData.payment_mode,
      payment_date:
        formData?.advance === 0 && mode !== "edit"
          ? null
          : formData?.payment_date === null || formData?.payment_date === "null"
          ? null
          : moment(formData.payment_date).format(),
      delivery_date:
        mode === "edit"
          ? moment(mainFormik?.values?.delivery_date).format()
          : moment(formData.delivery_date).format(),
      second_delivery_date: shouldShowDatePicker
        ? moment(mainFormik?.values?.second_delivery_date).format()
        : null,
      discount: parseFloat(formData.discount),
      due_amount:
        mode === "edit"
          ? parseFloat(
              (mainFormik?.values?.bill_amount || 0) -
                totalPayments -
                totalDiscounts -
                (mainFormik?.values?.advance || 0) -
                (mainFormik?.values?.discount || 0)
            )
          : formData.due_amount,
      order_status: formData.order_status,
      payment_details:
        mode === "" ||
        (mode === "create" &&
          (formData?.payment_mode === "" ||
            formData?.discount ||
            formData?.advance))
          ? [
              {
                id: 1,
                payment_date:
                  formData?.advance !== 0 ? formData?.payment_date : null,
                amount: parseFloat(formData?.advance),
                payment_mode:
                  formData?.advance !== 0 ? formData?.payment_mode : null,
                discount: parseFloat(formData?.discount),
                due_amount:
                  mode === "edit"
                    ? parseFloat(
                        (mainFormik?.values?.bill_amount || 0) -
                          totalPayments -
                          totalDiscounts -
                          (mainFormik?.values?.advance || 0) -
                          (mainFormik?.values?.discount || 0)
                      )
                    : parseFloat(formData.due_amount),
              },
            ]
          : mode?.toLowerCase() === "edit" // If mode is "edit"
          ? formData?.advance !== 0 || formData?.discount !== 0
            ? [
                ...payments,
                {
                  id: paymentDetailsArrLength + 1,
                  payment_date:
                    formData?.advance !== 0 ? formData?.payment_date : null,
                  amount: parseFloat(formData?.advance),
                  payment_mode:
                    formData?.advance !== 0 ? formData?.payment_mode : null,
                  discount: parseFloat(formData?.discount),
                  due_amount: parseFloat(
                    (mainFormik?.values?.bill_amount || 0) -
                      totalPayments -
                      totalDiscounts -
                      (mainFormik?.values?.advance || 0) -
                      (mainFormik?.values?.discount || 0)
                  ),
                },
              ]
            : payments
          : payments,
      is_second_delivery_date_req: shouldShowDatePicker,
      cancelled_msg: formData?.cancel_reason,
      shop_id: shopId,
    };
    // 2console.log("edit", data);
    const onSuccess = (res) => {
      dispatch(hideLoader());
      if (res?.data?.status === true) {
        getOrder(res?.data?.data?.id);

        dispatch(
          showSnackbar({
            message: res?.data?.msg || "Info updated successfully",
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "success",
          })
        );
      }
      setMode("print");
      if (mode === "edit") {
        handleClose();
      }
    };

    const onFailure = (err) => {
      dispatch(hideLoader());
      dispatch(
        showSnackbar({
          message: err?.response?.data?.msg || "Something went wrong",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "error",
        })
      );
    };
    if (mode === "edit") {
      orderApi.updateOrder(latestOrder?.id, data).then(onSuccess, onFailure);
    } else {
      orderApi.createOrder(data).then(onSuccess, onFailure);
    }
  };
  const mainFormik = useFormik({
    initialValues: {
      orddate: null,
      blouse_bi: "",
      blouse_sh: "",
      blouse_si: "",
      blouse_r: "",
      blouse_arm: "",
      blouse_up: "",
      blouse_ch: "",
      blouse_yoke: "",
      blouse_waist: "",
      blouse_bust: "",
      blouse_f: "",
      blouse_b: "",
      blouse_p2p: "",
      blouse_cut: "",
      set_toplen: "",
      set_sh: "",
      set_si: "",
      set_r: "",
      set_arm: "",
      set_up: "",
      set_ch: "",
      set_waist: "",
      set_seat: "",
      set_f: "",
      set_b: "",
      kneelen: "",
      boi: "",
      round: "",
      kround: "",
      thigh: "",
      waist: "",
      seat: "",
      notes: "",
      lining: "",
      quantity: 0,
      bill_amount: 0,
      advance: 0,
      payment_mode: "",
      payment_date: null,
      delivery_date: null,
      second_delivery_date: null,
      discount: 0,
      due_amount: "",
      order_status: mode === "edit" ? "" : "pending",
      cancel_reason: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      orddate:
        mode === "edit"
          ? Yup.date().nullable().notRequired()
          : Yup.date()
              .required("Order Date is required")
              .min(
                moment(latestOrderDate).startOf("day"),
                "Order Date must fall between recent order and current date"
              )
              .max(
                moment().endOf("day"),
                "Order Date must be on or before current date"
              ),
      blouse_bi: Yup.string().max(20, "Must be 20 characters or less"),
      blouse_sh: Yup.string().max(20, "Must be 20 characters or less"),
      blouse_si: Yup.string().max(20, "Must be 20 characters or less"),
      blouse_r: Yup.string().max(20, "Must be 20 characters or less"),
      blouse_arm: Yup.string().max(20, "Must be 20 characters or less"),
      blouse_up: Yup.string().max(20, "Must be 20 characters or less"),
      blouse_ch: Yup.string().max(20, "Must be 20 characters or less"),
      blouse_yoke: Yup.string().max(20, "Must be 20 characters or less"),
      blouse_waist: Yup.string().max(20, "Must be 20 characters or less"),
      blouse_bust: Yup.string().max(20, "Must be 20 characters or less"),
      blouse_f: Yup.string().max(20, "Must be 20 characters or less"),
      blouse_b: Yup.string().max(20, "Must be 20 characters or less"),
      blouse_p2p: Yup.string().max(20, "Must be 20 characters or less"),
      blouse_cut: Yup.string().max(20, "Must be 20 characters or less"),
      set_toplen: Yup.string().max(20, "Must be 20 characters or less"),
      set_sh: Yup.string().max(20, "Must be 20 characters or less"),
      set_si: Yup.string().max(20, "Must be 20 characters or less"),
      set_r: Yup.string().max(20, "Must be 20 characters or less"),
      set_arm: Yup.string().max(20, "Must be 20 characters or less"),
      set_up: Yup.string().max(20, "Must be 20 characters or less"),
      set_ch: Yup.string().max(20, "Must be 20 characters or less"),
      set_waist: Yup.string().max(20, "Must be 20 characters or less"),
      set_seat: Yup.string().max(20, "Must be 20 characters or less"),
      set_f: Yup.string().max(20, "Must be 20 characters or less"),
      set_b: Yup.string().max(20, "Must be 20 characters or less"),
      kneelen: Yup.string().max(20, "Must be 20 characters or less"),
      boi: Yup.string().max(20, "Must be 20 characters or less"),
      round: Yup.string().max(20, "Must be 20 characters or less"),
      kround: Yup.string().max(20, "Must be 20 characters or less"),
      thigh: Yup.string().max(20, "Must be 20 characters or less"),
      waist: Yup.string().max(20, "Must be 20 characters or less"),
      seat: Yup.string().max(20, "Must be 20 characters or less"),
      notes: Yup.string().max(5000, "Must be 5000 characters or less"),
      lining: Yup.string().max(15, "Must be 15 characters or less"),
      advance: Yup.number(),
      payment_mode: Yup.string(),
      payment_date: Yup.date()
        .nullable()
        .notRequired()
        .test({
          name: "payment-date",
          message:
            "Payment date must be after the order payment date or order date or till current date",
          test: function (value) {
            const { orddate, advance } = this.parent;
            // const context = this.options.context;
            const date =
              mode === "edit"
                ? latestOrder?.payment_date
                  ? latestOrder?.payment_date
                  : latestOrder?.order_date
                : orddate;

            if (advance <= 0) {
              return true;
            }

            const isAfterLatestPaymentDate = moment(value).isSameOrAfter(
              moment(date).startOf("day")
            );
            const doesNotExceedCurrentDate = moment(value).isSameOrBefore(
              moment().endOf("day")
            );
            return isAfterLatestPaymentDate && doesNotExceedCurrentDate;
          },
        }),

      delivery_date:
        mode === "edit"
          ? Yup.date().nullable().notRequired()
          : Yup.date()
              .required("Delivery Date is required")
              .test(
                "delivery-date",
                "Delivery date must be on or after order date",
                function (value) {
                  const orddate = this.resolve(Yup.ref("orddate"));
                  if (!orddate || !value) {
                    return true; // Return true if either date is not set (handled by other validations)
                  }
                  return moment(value)
                    .startOf("day")
                    .isSameOrAfter(moment(orddate).startOf("day"));
                }
              ),
      second_delivery_date: showSecondDeliveryDate
        ? Yup.date()
            .nullable()
            .required("Second Delivery Date is required")
            .min(
              Yup.ref("delivery_date"),
              "Second Delivery Date must be after Delivery Date"
            )
            .transform((value) => {
              // Check if the value is a string, then convert it to a Date
              return value && typeof value === "string"
                ? new Date(value)
                : value;
            })
        : Yup.date().nullable().notRequired(),
      discount: Yup.number(),
      due_amount: Yup.number().required("Due amount is required"),
      order_status: Yup.string(),
      is_second_delivery_date_req: Yup.boolean(),
      cancel_reason: Yup.string().nullable(),
    }),
    onSubmit: handleFinalSubmit,
  });

  const validateDateWithin7Days = (providedDate, referenceDate) => {
    if (!providedDate) {
      return true;
    }
    const startOfProvidedDate = moment(providedDate).startOf("day");
    const startOfReferenceDate = moment(referenceDate).startOf("day");
    const endDate = startOfProvidedDate.clone().add(7, "days").endOf("day");

    return startOfReferenceDate.isSameOrBefore(endDate);
  };
  const isWithin7Days = validateDateWithin7Days(
    latestOrder?.cancelled_date || latestOrder?.delivered_date,
    new Date("")
  );

  const allMobileNumber = () => {
    const onSuccess = (res) => {
      setMobileNumberOptions(res?.data?.data);
    };
    const onFailure = (err) => {
      console.error(err);
    };
    customerApi.getAllMobileNumber().then(onSuccess, onFailure);
  };
  useEffect(() => {
    if (open) {
      allMobileNumber();
    }
  }, [open]);

  const getCustomerNameOptions = (selectedMobileNumber) => {
    const data = selectedMobileNumber;
    const onSuccess = (res) => {
      if (res?.data?.status === true) {
        const customers = res?.data?.data;
        setCustomerNameOptions(customers);
        if (customers.length === 1) {
          const selectedCustomer = customers[0];
          formik?.setFieldValue("customerName", selectedCustomer);
          setCustomerId(selectedCustomer?.id);
          setCustomerData(selectedCustomer);
        } else {
          setLatestOrder(null);
          formik?.setFieldValue("customerName", null);
          setCustomerId(null);
          setSelectedHistoryData(null);
        }
      }
    };
    const onFailure = (err) => {};

    customerApi.getCustomerName(data).then(onSuccess, onFailure);
  };

  const getAllDressType = useCallback(() => {
    const onSuccess = (res) => {
      const dressTypeData =
        res?.data?.data?.map((item) => ({
          label: item.name,
        })) || [];
      setAllDressType(dressTypeData);
    };
    const onFailure = (err) => {};
    orderApi.getAllDressType().then(onSuccess, onFailure);
  });
  useEffect(() => {
    if (open) {
      getAllDressType();
    }
  }, [open]);

  const handleAddNewDressType = (newValue) => {
    const data = { name: newValue };
    const onSuccess = (res) => {};
    const onFailure = (err) => {};
    orderApi.dressTypeCreate(data).then(onSuccess, onFailure);
  };

  const getAllMaster = useCallback(() => {
    const onSuccess = (res) => {
      const masterData =
        res?.data?.data?.map((item) => ({
          label: item.name,
        })) || [];
      setAllMaster(masterData);
    };
    const onFailure = (err) => {};
    orderApi.getAllMaster().then(onSuccess, onFailure);
  });
  useEffect(() => {
    if (open) {
      getAllMaster();
    }
  }, [open]);

  const handleAddNewMaster = (newValue) => {
    const data = { name: newValue, shop_id: shopId };
    const onSuccess = (res) => {};
    const onFailure = (err) => {};
    orderApi.masterCreate(data).then(onSuccess, onFailure);
  };

  const getAllTailor = useCallback(() => {
    const onSuccess = (res) => {
      const tailorData =
        res?.data?.data?.map((item) => ({
          label: item.name,
        })) || [];
      setAllTailor(tailorData);
    };
    const onFailure = (err) => {};
    orderApi.getAllTailor().then(onSuccess, onFailure);
  });
  useEffect(() => {
    if (open) {
      getAllTailor();
    }
  }, [open]);

  const handleAddNewTailor = (newValue) => {
    const data = { name: newValue, shop_id: shopId };
    const onSuccess = (res) => {};
    const onFailure = (err) => {};
    orderApi.tailorCreate(data).then(onSuccess, onFailure);
  };

  const getOrderHistory = () => {
    const onSuccess = (res) => {
      const data = res?.data?.data;
      setOrderHistoryList(data);
      setTotalBillAmount(res?.data?.totalBillAmount || 0);
      setTotalDiscountAmount(res?.data?.totalDiscountAmount || 0);
      const latestData = res?.data?.latestOrder;
      if (latestData === null) {
        setLatestOrder([]);
        setOrderHistoryList([]);
        mainFormik?.resetForm();
      } else {
        setLatestOrder(latestData);
      }

      setOrderData(data);
    };

    const onFailure = (err) => {
      setOrderHistoryList([]);
      setTotalBillAmount(0);
      setTotalDiscountAmount(0);
      setLatestOrder([]);
      setOrderData([]);
    };

    if (customerId) {
      orderApi.orderHistory(customerId).then(onSuccess, onFailure);
    }
  };
  useEffect(() => {
    if (open) {
      setOrderHistoryList([]);
      setLatestOrder(null);
      if (customerId) {
        getOrderHistory();
      }
    }
  }, [customerId, open]);

  useEffect(() => {
    if ((open && mode === "edit") || (open && mode === "view")) {
      setLatestOrder(orderParticularData);
    }
  }, [orderParticularData, open]);

  useEffect(() => {
    if (mode === "orderhistory" && !_.isEmpty(selectedHistoryData)) {
      const parsedParticulars = selectedHistoryData?.particulars || [];
      mainFormik.setFieldValue("blouse_bi", selectedHistoryData?.blouse_bi);
      mainFormik.setFieldValue("blouse_sh", selectedHistoryData?.blouse_sh);
      mainFormik.setFieldValue("blouse_si", selectedHistoryData?.blouse_si);
      mainFormik.setFieldValue("blouse_r", selectedHistoryData?.blouse_r);
      mainFormik.setFieldValue("blouse_arm", selectedHistoryData?.blouse_arm);
      mainFormik.setFieldValue("blouse_up", selectedHistoryData?.blouse_up);
      mainFormik.setFieldValue("blouse_ch", selectedHistoryData?.blouse_ch);
      mainFormik.setFieldValue("blouse_yoke", selectedHistoryData?.blouse_yoke);
      mainFormik.setFieldValue(
        "blouse_waist",
        selectedHistoryData?.blouse_waist
      );
      mainFormik.setFieldValue("blouse_bust", selectedHistoryData?.blouse_bust);
      mainFormik.setFieldValue("blouse_f", selectedHistoryData?.blouse_f);
      mainFormik.setFieldValue("blouse_b", selectedHistoryData?.blouse_b);
      mainFormik.setFieldValue("blouse_p2p", selectedHistoryData?.blouse_p2p);
      mainFormik.setFieldValue("set_toplen", selectedHistoryData?.set_toplen);
      mainFormik.setFieldValue("set_sh", selectedHistoryData?.set_sh);
      mainFormik.setFieldValue("set_si", selectedHistoryData?.set_si);
      mainFormik.setFieldValue("set_r", selectedHistoryData?.set_r);
      mainFormik.setFieldValue("set_arm", selectedHistoryData?.set_arm);
      mainFormik.setFieldValue("set_up", selectedHistoryData?.set_up);
      mainFormik.setFieldValue("set_ch", selectedHistoryData?.set_ch);
      mainFormik.setFieldValue("set_waist", selectedHistoryData?.set_waist);
      mainFormik.setFieldValue("set_seat", selectedHistoryData?.set_seat);
      mainFormik.setFieldValue("set_f", selectedHistoryData?.set_f);
      mainFormik.setFieldValue("set_b", selectedHistoryData?.set_b);
      mainFormik.setFieldValue("kneelen", selectedHistoryData?.kneelen);
      mainFormik.setFieldValue("boi", selectedHistoryData?.boi);
      mainFormik.setFieldValue("round", selectedHistoryData?.round);
      mainFormik.setFieldValue("kround", selectedHistoryData?.kround);
      mainFormik.setFieldValue("thigh", selectedHistoryData?.thigh);
      mainFormik.setFieldValue("waist", selectedHistoryData?.waist);
      mainFormik.setFieldValue("seat", selectedHistoryData?.seat);
      mainFormik.setFieldValue("notes", selectedHistoryData?.notes);
      mainFormik.setFieldValue("lining", selectedHistoryData?.lining);
      mainFormik.setFieldValue("quantity", selectedHistoryData?.quantity);
      mainFormik.setFieldValue("bill_amount", selectedHistoryData?.bill_amount);
      mainFormik.setFieldValue(
        "cancel_reason",
        selectedHistoryData?.cancelled_msg
      );
    } else if (mode === "view" && !_.isEmpty(latestOrder)) {
      const parsedParticulars = latestOrder?.particulars || [];
      mainFormik.setFieldValue("blouse_bi", latestOrder?.blouse_bi);
      mainFormik.setFieldValue("blouse_sh", latestOrder?.blouse_sh);
      mainFormik.setFieldValue("blouse_si", latestOrder?.blouse_si);
      mainFormik.setFieldValue("blouse_r", latestOrder?.blouse_r);
      mainFormik.setFieldValue("blouse_arm", latestOrder?.blouse_arm);
      mainFormik.setFieldValue("blouse_up", latestOrder?.blouse_up);
      mainFormik.setFieldValue("blouse_ch", latestOrder?.blouse_ch);
      mainFormik.setFieldValue("blouse_yoke", latestOrder?.blouse_yoke);
      mainFormik.setFieldValue("blouse_waist", latestOrder?.blouse_waist);
      mainFormik.setFieldValue("blouse_bust", latestOrder?.blouse_bust);
      mainFormik.setFieldValue("blouse_f", latestOrder?.blouse_f);
      mainFormik.setFieldValue("blouse_b", latestOrder?.blouse_b);
      mainFormik.setFieldValue("blouse_p2p", latestOrder?.blouse_p2p);
      mainFormik.setFieldValue("blouse_cut", latestOrder?.blouse_cut);
      mainFormik.setFieldValue("set_toplen", latestOrder?.set_toplen);
      mainFormik.setFieldValue("set_sh", latestOrder?.set_sh);
      mainFormik.setFieldValue("set_si", latestOrder?.set_si);
      mainFormik.setFieldValue("set_r", latestOrder?.set_r);
      mainFormik.setFieldValue("set_arm", latestOrder?.set_arm);
      mainFormik.setFieldValue("set_up", latestOrder?.set_up);
      mainFormik.setFieldValue("set_ch", latestOrder?.set_ch);
      mainFormik.setFieldValue("set_waist", latestOrder?.set_waist);
      mainFormik.setFieldValue("set_seat", latestOrder?.set_seat);
      mainFormik.setFieldValue("set_f", latestOrder?.set_f);
      mainFormik.setFieldValue("set_b", latestOrder?.set_b);
      mainFormik.setFieldValue("kneelen", latestOrder?.kneelen);
      mainFormik.setFieldValue("boi", latestOrder?.boi);
      mainFormik.setFieldValue("round", latestOrder?.round);
      mainFormik.setFieldValue("kround", latestOrder?.kround);
      mainFormik.setFieldValue("thigh", latestOrder?.thigh);
      mainFormik.setFieldValue("waist", latestOrder?.waist);
      mainFormik.setFieldValue("seat", latestOrder?.seat);
      mainFormik.setFieldValue("notes", latestOrder?.notes);
      mainFormik.setFieldValue("lining", latestOrder?.lining);
      mainFormik.setFieldValue("quantity", latestOrder?.quantity);
      mainFormik.setFieldValue("bill_amount", latestOrder?.bill_amount);
      mainFormik.setFieldValue("advance", totalAmount);
      mainFormik.setFieldValue("due_amount", latestOrder?.due_amount);
      mainFormik.setFieldValue("discount", totalDiscount);
      mainFormik.setFieldValue("cancel_reason", latestOrder?.cancelled_msg);
    } else if (mode === "edit" && !_.isEmpty(latestOrder)) {
      const parsedParticulars = latestOrder?.particulars || [];
      setPayments(latestOrder?.payment_details);
      mainFormik.setFieldValue("order_date", latestOrder?.order_date);
      mainFormik.setFieldValue("blouse_bi", latestOrder?.blouse_bi);
      mainFormik.setFieldValue("blouse_sh", latestOrder?.blouse_sh);
      mainFormik.setFieldValue("blouse_si", latestOrder?.blouse_si);
      mainFormik.setFieldValue("blouse_r", latestOrder?.blouse_r);
      mainFormik.setFieldValue("blouse_arm", latestOrder?.blouse_arm);
      mainFormik.setFieldValue("blouse_up", latestOrder?.blouse_up);
      mainFormik.setFieldValue("blouse_ch", latestOrder?.blouse_ch);
      mainFormik.setFieldValue("blouse_yoke", latestOrder?.blouse_yoke);
      mainFormik.setFieldValue("blouse_waist", latestOrder?.blouse_waist);
      mainFormik.setFieldValue("blouse_bust", latestOrder?.blouse_bust);
      mainFormik.setFieldValue("blouse_f", latestOrder?.blouse_f);
      mainFormik.setFieldValue("blouse_b", latestOrder?.blouse_b);
      mainFormik.setFieldValue("blouse_p2p", latestOrder?.blouse_p2p);
      mainFormik.setFieldValue("blouse_cut", latestOrder?.blouse_cut);
      mainFormik.setFieldValue("set_toplen", latestOrder?.set_toplen);
      mainFormik.setFieldValue("set_sh", latestOrder?.set_sh);
      mainFormik.setFieldValue("set_si", latestOrder?.set_si);
      mainFormik.setFieldValue("set_r", latestOrder?.set_r);
      mainFormik.setFieldValue("set_arm", latestOrder?.set_arm);
      mainFormik.setFieldValue("set_up", latestOrder?.set_up);
      mainFormik.setFieldValue("set_ch", latestOrder?.set_ch);
      mainFormik.setFieldValue("set_waist", latestOrder?.set_waist);
      mainFormik.setFieldValue("set_seat", latestOrder?.set_seat);
      mainFormik.setFieldValue("set_f", latestOrder?.set_f);
      mainFormik.setFieldValue("set_b", latestOrder?.set_b);
      mainFormik.setFieldValue("kneelen", latestOrder?.kneelen);
      mainFormik.setFieldValue("boi", latestOrder?.boi);
      mainFormik.setFieldValue("round", latestOrder?.round);
      mainFormik.setFieldValue("kround", latestOrder?.kround);
      mainFormik.setFieldValue("thigh", latestOrder?.thigh);
      mainFormik.setFieldValue("waist", latestOrder?.waist);
      mainFormik.setFieldValue("seat", latestOrder?.seat);
      mainFormik.setFieldValue("notes", latestOrder?.notes);
      mainFormik.setFieldValue("lining", latestOrder?.lining);
      mainFormik.setFieldValue("quantity", latestOrder?.quantity);
      mainFormik.setFieldValue("bill_amount", latestOrder?.bill_amount);
      mainFormik.setFieldValue("payment_mode", latestOrder?.payment_mode);
      mainFormik.setFieldValue("order_status", latestOrder?.order_status);
      mainFormik.setFieldValue("cancel_reason", latestOrder?.cancelled_msg);
      mainFormik.setFieldValue("due_amount", latestOrder?.due_amount);
      mainFormik?.setFieldValue(
        "payment_date",
        latestOrder?.payment_date === null ||
          latestOrder?.payment_date === "null" ||
          latestOrder?.payment_date === undefined ||
          !latestOrder?.payment_date
          ? null
          : moment(latestOrder?.payment_date)
      );
      mainFormik?.setFieldValue(
        "delivery_date",
        latestOrder?.delivery_date ? moment(latestOrder?.delivery_date) : null
      );
      mainFormik?.setFieldValue(
        "second_delivery_date",
        latestOrder?.second_delivery_date
          ? moment(latestOrder?.second_delivery_date)
          : moment()
      );
      setEditSecondDeliveryDate(latestOrder?.is_second_delivery_date_req);
    } else if (mode === "print" && !_.isEmpty(latestOrder)) {
      const parsedParticulars = latestOrder?.particulars || [];
      mainFormik.setFieldValue("blouse_bi", latestOrder?.blouse_bi);
      mainFormik.setFieldValue("blouse_sh", latestOrder?.blouse_sh);
      mainFormik.setFieldValue("blouse_si", latestOrder?.blouse_si);
      mainFormik.setFieldValue("blouse_r", latestOrder?.blouse_r);
      mainFormik.setFieldValue("blouse_arm", latestOrder?.blouse_arm);
      mainFormik.setFieldValue("blouse_up", latestOrder?.blouse_up);
      mainFormik.setFieldValue("blouse_ch", latestOrder?.blouse_ch);
      mainFormik.setFieldValue("blouse_yoke", latestOrder?.blouse_yoke);
      mainFormik.setFieldValue("blouse_waist", latestOrder?.blouse_waist);
      mainFormik.setFieldValue("blouse_bust", latestOrder?.blouse_bust);
      mainFormik.setFieldValue("blouse_p2p", latestOrder?.blouse_p2p);
      mainFormik.setFieldValue("blouse_f", latestOrder?.blouse_f);
      mainFormik.setFieldValue("blouse_b", latestOrder?.blouse_b);
      mainFormik.setFieldValue("blouse_cut", latestOrder?.blouse_cut);
      mainFormik.setFieldValue("set_toplen", latestOrder?.set_toplen);
      mainFormik.setFieldValue("set_sh", latestOrder?.set_sh);
      mainFormik.setFieldValue("set_si", latestOrder?.set_si);
      mainFormik.setFieldValue("set_r", latestOrder?.set_r);
      mainFormik.setFieldValue("set_arm", latestOrder?.set_arm);
      mainFormik.setFieldValue("set_up", latestOrder?.set_up);
      mainFormik.setFieldValue("set_ch", latestOrder?.set_ch);
      mainFormik.setFieldValue("set_waist", latestOrder?.set_waist);
      mainFormik.setFieldValue("set_seat", latestOrder?.set_seat);
      mainFormik.setFieldValue("set_f", latestOrder?.set_f);
      mainFormik.setFieldValue("set_b", latestOrder?.set_b);
      mainFormik.setFieldValue("kneelen", latestOrder?.kneelen);
      mainFormik.setFieldValue("boi", latestOrder?.boi);
      mainFormik.setFieldValue("round", latestOrder?.round);
      mainFormik.setFieldValue("kround", latestOrder?.kround);
      mainFormik.setFieldValue("thigh", latestOrder?.thigh);
      mainFormik.setFieldValue("waist", latestOrder?.waist);
      mainFormik.setFieldValue("seat", latestOrder?.seat);
      mainFormik.setFieldValue("notes", latestOrder?.seat);
      mainFormik.setFieldValue("lining", latestOrder?.lining);
      mainFormik.setFieldValue("quantity", latestOrder?.quantity);
      mainFormik.setFieldValue("bill_amount", latestOrder?.bill_amount);
      mainFormik.setFieldValue("seat", latestOrder?.seat);
      mainFormik.setFieldValue("notes", latestOrder?.notes);
      mainFormik.setFieldValue("lining", latestOrder?.lining);
      mainFormik.setFieldValue("quantity", latestOrder?.quantity);
      mainFormik.setFieldValue("bill_amount", latestOrder?.bill_amount);
      mainFormik.setFieldValue("advance", latestOrder?.advance);
      mainFormik.setFieldValue("cancel_reason", latestOrder?.cancelled_msg);
    } else if (mode !== "orderhistory" && !_.isEmpty(latestOrder)) {
      const parsedParticulars = latestOrder?.particulars || [];
      mainFormik.setFieldValue("blouse_bi", latestOrder?.blouse_bi);
      mainFormik.setFieldValue("blouse_sh", latestOrder?.blouse_sh);
      mainFormik.setFieldValue("blouse_si", latestOrder?.blouse_si);
      mainFormik.setFieldValue("blouse_r", latestOrder?.blouse_r);
      mainFormik.setFieldValue("blouse_arm", latestOrder?.blouse_arm);
      mainFormik.setFieldValue("blouse_up", latestOrder?.blouse_up);
      mainFormik.setFieldValue("blouse_ch", latestOrder?.blouse_ch);
      mainFormik.setFieldValue("blouse_yoke", latestOrder?.blouse_yoke);
      mainFormik.setFieldValue("blouse_waist", latestOrder?.blouse_waist);
      mainFormik.setFieldValue("blouse_bust", latestOrder?.blouse_bust);
      mainFormik.setFieldValue("blouse_f", latestOrder?.blouse_f);
      mainFormik.setFieldValue("blouse_b", latestOrder?.blouse_b);
      mainFormik.setFieldValue("blouse_p2p", latestOrder?.blouse_p2p);
      mainFormik.setFieldValue("blouse_cut", latestOrder?.blouse_cut);
      mainFormik.setFieldValue("set_toplen", latestOrder?.set_toplen);
      mainFormik.setFieldValue("set_sh", latestOrder?.set_sh);
      mainFormik.setFieldValue("set_si", latestOrder?.set_si);
      mainFormik.setFieldValue("set_r", latestOrder?.set_r);
      mainFormik.setFieldValue("set_arm", latestOrder?.set_arm);
      mainFormik.setFieldValue("set_up", latestOrder?.set_up);
      mainFormik.setFieldValue("set_ch", latestOrder?.set_ch);
      mainFormik.setFieldValue("set_waist", latestOrder?.set_waist);
      mainFormik.setFieldValue("set_seat", latestOrder?.set_seat);
      mainFormik.setFieldValue("set_f", latestOrder?.set_f);
      mainFormik.setFieldValue("set_b", latestOrder?.set_b);
      mainFormik.setFieldValue("kneelen", latestOrder?.kneelen);
      mainFormik.setFieldValue("boi", latestOrder?.boi);
      mainFormik.setFieldValue("round", latestOrder?.round);
      mainFormik.setFieldValue("kround", latestOrder?.kround);
      mainFormik.setFieldValue("thigh", latestOrder?.thigh);
      mainFormik.setFieldValue("waist", latestOrder?.waist);
      mainFormik.setFieldValue("seat", latestOrder?.seat);
      mainFormik.setFieldValue("notes", "");
      mainFormik.setFieldValue("lining", "");
      mainFormik.setFieldValue("quantity", "");
      mainFormik.setFieldValue("bill_amount", "");
      mainFormik.setFieldValue("cancel_reason", latestOrder?.cancelled_msg);
      mainFormik?.setFieldValue(
        "payment_date",
        latestOrder?.payment_date === null ||
          latestOrder?.payment_date === "null" ||
          latestOrder?.payment_date === undefined ||
          !latestOrder?.payment_date
          ? null
          : moment(latestOrder?.payment_date)
      );
    }
  }, [latestOrder, selectedHistoryData, mode]);

  let parsedOrderData = [];

  if (mode === "view") {
    parsedOrderData = latestOrder?.particulars ? latestOrder?.particulars : [];
  } else if (mode === "orderhistory") {
    parsedOrderData = selectedHistoryData?.particulars
      ? selectedHistoryData?.particulars
      : [];
  }

  useEffect(() => {
    if (open && mode === "edit") {
      const parsedOrderData = latestOrder?.particulars
        ? latestOrder?.particulars
        : [];
      setOrderDetails(parsedOrderData);
    }
  }, [mode, latestOrder, open]);

  {
    console.log("sample", latestOrder);
  }
  const handleClose = () => {
    console.log("handleClose called");
    setOpen(false);
    mainFormik.resetForm();
    formik.resetForm();
    setOrderDetails([]);
    formik.setFieldValue("mobileNumber", "");
    orderFormik.setFieldValue("dressType", "");
    orderFormik.setFieldValue("master", "");
    orderFormik.setFieldValue("tailor", "");
    setInputValue("");
    orderFormik.resetForm();
    setMode(null);
    // setGetCustomer({});
    setLatestOrder(() => null);
    console.log("latestOrder reset to null");
    setCustomerData(null);
    setCustomerId(null);
    setCustomerNameOptions(null);
    getAllOrderList();
    setLatestOrderDate(moment());
    setLatestPaymentDate(moment());
    setSelectPaymentDate(moment());
    setPayments([]);
    sessionStorage.removeItem("internalCopy");
    sessionStorage.removeItem("customerCopy");
    setCheckedItems({});
    setSelectedHistoryData(null);
    // window.location.reload();
    setMobileNumberOptions([]);
  };

  const workPrint = () => {
    window.open(`/orderprint/${latestOrder?.uuid}`, "_blank");
  };
  const customerPrint = () => {
    window.open(`/customerprint/${latestOrder?.uuid}`, "_blank");
  };

  useEffect(() => {
    if (latestOrder && latestOrder?.payment_details) {
      try {
        const parsedDetails = Array.isArray(latestOrder.payment_details)
          ? latestOrder.payment_details
          : [latestOrder.payment_details]; // Wrap in an array if it's an object
        setParsedPaymentDetails(parsedDetails);
      } catch (error) {
        console.error("Error parsing payment details:", error);
      }
    }
  }, [latestOrder]);

  useEffect(() => {
    const totalAmount = parsedPaymentDetails.reduce((accumulator, item) => {
      return accumulator + item.amount;
    }, 0);

    const totalDiscount = parsedPaymentDetails.reduce((accumulator, item) => {
      return accumulator + item.discount;
    }, 0);

    setTotalAmount(parseFloat(totalAmount));
    setTotalPaidAmount(parseFloat(totalAmount + totalDiscount));
    setTotalDiscount(parseFloat(totalDiscount));
  }, [parsedPaymentDetails]);

  useEffect(() => {
    if (parsedPaymentDetails.length > 0) {
      const lastPaymentDetail =
        parsedPaymentDetails[parsedPaymentDetails.length - 1];
      if (!_.isEmpty(lastPaymentDetail) && mode !== "edit") {
        mainFormik?.setFieldValue(
          "payment_date",
          lastPaymentDetail?.payment_date
            ? moment(lastPaymentDetail?.payment_date)
            : null
        );
      }
    } else {
    }
  }, [parsedPaymentDetails]);

  // const { names, cusIds, refNames, location, id } = getCustomer;

  const handleOpen = () => {
    setCreateCustomer(true);
  };
  const handlehistoryOpen = () => {
    setHisopen(true);
  };
  const handlehisClose = () => {
    setHisopen(false);
  };

  const calculateSumOfQty = (orderDetails) => {
    return orderDetails.reduce(
      (total, order) => total + parseInt(order.qty),
      0
    );
  };

  const calculateSumOfAmount = (orderDetails) => {
    return orderDetails.reduce(
      (total, order) => total + parseInt(order.amount),
      0
    );
  };

  useEffect(() => {
    setSumOfQty(calculateSumOfQty(orderDetails));
    setSumOfAmount(calculateSumOfAmount(orderDetails));
    mainFormik.setFieldValue("quantity", sumOfQty);
    mainFormik.setFieldValue("bill_amount", sumOfAmount);
  }, [orderDetails, sumOfQty, sumOfAmount]);

  useEffect(() => {
    setSumOfQty(calculateSumOfQty(orderDetails));
    setSumOfAmount(calculateSumOfAmount(orderDetails));
  }, []);

  const getOrder = (createdOrderId) => {
    const onSuccess = (res) => {
      dispatch(hideLoader());
      setLatestOrder(res?.data?.data);
      setPayments(res?.data?.data?.payment_details);
    };
    const onFailure = () => {};
    orderApi.getOrder(createdOrderId).then(onSuccess).catch(onFailure);
  };
  useEffect(() => {
    if (open && mode === "edit" && !_.isEmpty(latestOrder)) {
      mainFormik?.setFieldValue("orddate", latestOrder?.order_date);
      mainFormik?.setFieldValue("delivery_date", latestOrder?.delivery_date);
      mainFormik?.setFieldValue(
        "second_delivery_date",
        latestOrder?.second_delivery_date
      );
    }
  }, [mode, latestOrder, open]);

  // const handleCheckboxChange = (id) => {
  //   const updatedCheckedItems = { ...checkedItems };
  //   updatedCheckedItems[id] = !updatedCheckedItems[id];

  //   // Update orderDetails based on checked status
  //   const updatedOrderDetails = orderDetails.map((detail) => {
  //     if (detail.id === id) {
  //       return {
  //         ...detail,
  //         checked: updatedCheckedItems[id],
  //       };
  //     }
  //     return detail;
  //   });

  //   setCheckedItems(updatedCheckedItems);
  //   setOrderDetails(updatedOrderDetails);
  // };

  // const handleCheckAll = () => {
  //   const isAllChecked = orderDetails.every((detail) => detail.checked);

  //   // Toggle check all
  //   const allChecked = {};
  //   const updatedOrderDetails = orderDetails.map((detail) => {
  //     allChecked[detail.id] = !isAllChecked;
  //     return {
  //       ...detail,
  //       checked: !isAllChecked,
  //     };
  //   });

  //   setCheckedItems(allChecked);
  //   setOrderDetails(updatedOrderDetails);
  // };

  const handleCheckboxChange = (id) => {
    // Toggle the checked state for the individual checkbox
    const updatedCheckedItems = { ...checkedItems, [id]: !checkedItems[id] };

    // Update orderDetails based on the updated checked state
    const updatedOrderDetails = orderDetails.map((detail) => {
      if (detail.id === id) {
        return {
          ...detail,
          checked: updatedCheckedItems[id],
        };
      }
      return detail;
    });

    // Update state
    setCheckedItems(updatedCheckedItems);
    setOrderDetails(updatedOrderDetails);
  };

  const handleCheckAll = (event) => {
    const isChecked = event.target.checked;
    setIsCheckboxChecked(isChecked);

    // Update checkedItems and orderDetails based on the checkbox state
    const updatedCheckedItems = {};
    const updatedOrderDetails = orderDetails.map((detail) => {
      updatedCheckedItems[detail.id] = isChecked;
      return {
        ...detail,
        checked: isChecked,
      };
    });

    setCheckedItems(updatedCheckedItems);
    setOrderDetails(updatedOrderDetails);
  };

  useEffect(() => {
    const initialCheckedItems = orderDetails.reduce((acc, item) => {
      acc[item.id] = item.checked;
      return acc;
    }, {});
    setCheckedItems(initialCheckedItems);
  }, [orderDetails]);
  const allChecked = orderDetails.every((detail) => detail.checked);

  // Save checkedItems to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("checkedItems", JSON.stringify(checkedItems));
  }, [checkedItems]);

  useEffect(() => {}, [payments]);
  const getOrderDate = () => {
    const onSuccess = (res) => {
      const orderDate =
        res?.data?.latestOrder?.order_date === null ||
        res?.data?.latestOrder?.order_date === " " ||
        res?.data?.latestOrder?.order_date === undefined
          ? moment().subtract(100, "years")
          : moment(res?.data?.latestOrder?.order_date);
      const paymentDate = moment(res?.data?.latestPaymentOrder?.payment_date);
      setLatestOrderDate(orderDate);
      // mainFormik.setFieldValue("orddate", moment());
      setLatestPaymentDate(paymentDate);
      setSelectPaymentDate(paymentDate);
      if (mode !== "edit") {
        mainFormik.setFieldValue(
          "payment_date",
          paymentDate === null ||
            paymentDate === "null" ||
            paymentDate === undefined ||
            !paymentDate
            ? null
            : moment(paymentDate)
        );
      }
    };

    const onFailure = (err) => {};

    orderApi.getOrderDate().then(onSuccess, onFailure);
  };
  useEffect(() => {
    if (open) {
      getOrderDate();
    }
  }, [open]);

  const handleDateChange = (newDate) => {
    const momentDate = moment(newDate);
    setLatestOrderDate(momentDate);
    mainFormik.setFieldValue("orddate", momentDate);
  };

  useEffect(() => {
    if (mode !== "edit") {
      const newPayment = {
        id: 1,
        payment_date: mainFormik?.values?.payment_date,
        amount: mainFormik?.values?.advance,
        payment_mode: mainFormik?.values?.payment_mode,
        discount: mainFormik?.values?.discount,
      };
      setPayments([newPayment]);
    }
  }, [
    mode,
    mainFormik.values.payment_mode,
    mainFormik.values.payment_date,
    mainFormik.values.advance,
    mainFormik.values.discount,
  ]);
  useEffect(() => {
    if (!mainFormik.dirty) return;

    const parsedAdvance = parseFloat(mainFormik.values.advance) || 0;
    const parsedDiscount = parseFloat(mainFormik.values.discount) || 0;
    const parsedBillAmount = parseFloat(mainFormik.values.bill_amount) || 0;

    const effectiveAdvance = Math.max(parsedAdvance, 0);
    const effectiveDiscount = Math.max(parsedDiscount, 0);

    let calculatedDueAmount;

    // Calculate due_amount without considering latestOrder
    if (mode === "edit") {
      calculatedDueAmount =
        parsedBillAmount -
        totalAmount -
        totalDiscount -
        latestOrder?.discount -
        latestOrder?.advance;
      // 2console.log("Calculated Due Amount (Edit Mode):", calculatedDueAmount);
    } else {
      calculatedDueAmount =
        parsedBillAmount - effectiveAdvance - effectiveDiscount;
    }
    mainFormik.setFieldValue("discount", effectiveDiscount);
    // Check if entered advance exceeds due amount
    if (mode !== "view" && mode !== "print") {
      if (parsedAdvance > parsedBillAmount - parsedDiscount) {
        dispatch(
          showSnackbar({
            message: "Advance or Discount amount cannot exceed the due amount!",
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "warning",
          })
        );
        mainFormik.setFieldValue("advance", "0"); // Resetting advance field
      } else {
        mainFormik.setFieldValue(
          "due_amount",
          Math.max(calculatedDueAmount, 0)
        );
      }
    }
  }, [
    mainFormik.dirty,
    mainFormik.values.advance,
    mainFormik.values.discount,
    mainFormik.values.bill_amount,
  ]);

  const handleAdvanceChange = (event) => {
    const { value } = event.target;
    const parsedAdvance = parseFloat(value) || "";
    const effectiveAdvance = Math.max(parsedAdvance, "");
    const parsedBillAmount = parseFloat(mainFormik.values.bill_amount) || "";

    if (effectiveAdvance > parsedBillAmount) {
      dispatch(
        showSnackbar({
          message: "Advance cannot exceed the bill amount!",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "warning",
        })
      );
      return;
    }

    mainFormik.setFieldValue("advance", effectiveAdvance);
    mainFormik.setFieldValue("advance", effectiveAdvance);
  };

  const filter = createFilterOptions();

  const handleMobileNumberChange = (event, value) => {
    if (value.inputValue?.length === 11) {
      dispatch(
        showSnackbar({
          message: "Mobile Number should be 10 digits",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "warning",
        })
      );
    } else if (value && value.inputValue && value.inputValue?.length === 10) {
      // Update formik and mobileNumberOptions
      formik?.setFieldValue("mobileNumber", {
        user: { mobile_number: value.inputValue },
      });
      setMobileNumberOptions([
        ...mobileNumberOptions,
        { user: { mobile_number: value.inputValue } },
      ]);
      if (mode === "orderhistory") {
        dispatch(
          showSnackbar({
            message: "Mobile Number not registered",
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "warning",
          })
        );
      } else {
        handleOpen();
      }
    } else {
      // Update formik and show customer info
      formik?.setFieldValue("mobileNumber", value);
      getCustomerNameOptions(value?.user?.mobile_number);
      setShowCustomerInfo(true);
    }
  };

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${formattedDay}-${formattedMonth}-${year}`;
  };
  const handleNewOrder = () => {
    setLatestOrder(selectedHistoryData);
    setMode("");
  };
  const handleOrderHistoryChange = (event, newValue) => {
    setSelectedHistoryData(newValue);
  };
  useEffect(() => {
    if (orderData?.length === 1) {
      const singleOrder = orderData[0];
      setSelectedHistoryData(singleOrder);
      handleOrderHistoryChange(null, singleOrder);
      setFormReset(false); // Ensure formReset is false when orderData has one item
    } else if (!orderData || orderData.length === 0) {
      setSelectedHistoryData(null);
      handleOrderHistoryChange(null, null);
      if (!formReset) {
        mainFormik?.resetForm();
        setFormReset(true);
      }
    } else {
      setFormReset(false); // Ensure formReset is false when orderData has multiple items
    }
  }, [orderData, handleOrderHistoryChange, mainFormik, formReset]);

  const isReadOnly =
    mode === "orderhistory" || mode === "view" || mode === "print";
  // const handleDeliveryTypeChange = (e) => {
  //   const selectedValue = e.target.value;

  //   // if (selectedValue === "2nd Delivery Date" && !firstDeliveryChosen) {
  //   //   dispatch(
  //   //     showSnackbar({
  //   //       message:
  //   //         "You must choose the '1st Delivery Date' before selecting '2nd Delivery Date'",
  //   //       autoHideDuration: 3000,
  //   //       anchorOrigin: {
  //   //         vertical: "top",
  //   //         horizontal: "right",
  //   //       },
  //   //       variant: "warning",
  //   //     })
  //   //   );
  //   //   return;
  //   // }
  //   if (selectedValue === "2nd Delivery Date") {
  //     if (mode !== "edit" && !firstDeliveryChosen) {
  //       dispatch(
  //         showSnackbar({
  //           message:
  //             "You must choose the '1st Delivery Date' before selecting '2nd Delivery Date'",
  //           autoHideDuration: 3000,
  //           anchorOrigin: {
  //             vertical: "top",
  //             horizontal: "right",
  //           },
  //           variant: "warning",
  //         })
  //       );
  //       return;
  //     }
  //     if (mode === "edit" && latestOrder.delivery_date) {
  //       // Allow "2nd Delivery Date" selection if in edit mode and `latestOrder.delivery_date` exists
  //       return;
  //     }
  //     // Additional logic for handling "2nd Delivery Date" selection
  //   }
  //   orderFormik.handleChange(e);

  //   if (selectedValue === "1st Delivery Date") {
  //     setShowSecondDeliveryDate(false);
  //     setFirstDeliveryChosen(true);
  //   } else if (selectedValue === "2nd Delivery Date") {
  //     setShowSecondDeliveryDate(true);
  //   } else {
  //     setShowSecondDeliveryDate(false);
  //   }
  // };

  const handleDeliveryTypeChange = (e) => {
    const selectedValue = e.target.value;
    // 2console.log("Selected Value:", selectedValue);

    // When selecting "2nd Delivery Date"
    if (
      selectedValue === "2nd Delivery Date" &&
      parsedOrderData.some((head) => head.deltype === "1st Delivery Date")
    ) {
      if (mode === "edit") {
        // In edit mode, check if "1st Delivery Date" exists in the current parsedOrderData
        const hasFirstDeliveryDate = parsedOrderData.some(
          (head) => head.deltype === "1st Delivery Date"
        );

        if (!hasFirstDeliveryDate) {
          // If "1st Delivery Date" is missing, show warning
          dispatch(
            showSnackbar({
              message:
                "'2nd Delivery Date' cannot be selected unless '1st Delivery Date' is set.",
              autoHideDuration: 3000,
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "warning",
            })
          );
          return; // Exit early if the condition is not met
        }
      } else if (mode !== "edit" && !firstDeliveryChosen) {
        // If not in edit mode and no "1st Delivery Date" chosen, show warning
        dispatch(
          showSnackbar({
            message:
              "You must choose the '1st Delivery Date' before selecting '2nd Delivery Date'",
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "warning",
          })
        );
        return; // Exit early if the condition is not met
      }
    }

    // Update Formik value
    orderFormik.setFieldValue("deltype", selectedValue);

    // Update local state based on the selected value
    if (selectedValue === "1st Delivery Date") {
      // setShowSecondDeliveryDate(false);
      // setFirstDeliveryChosen(true);
    } else if (selectedValue === "2nd Delivery Date") {
      // setShowSecondDeliveryDate(true);
    } else {
      // setShowSecondDeliveryDate(false);
      // setFirstDeliveryChosen(false); // Optional: reset firstDeliveryChosen if other options are selected
    }

    // Log Formik values for debugging
    // 2console.log("Formik Values:", orderFormik.values);
  };

  const isCancellationOld = (cancelledDate) => {
    if (!cancelledDate) return false;
    const cancellationDate = new Date(cancelledDate);
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7); // Calculate one week ago
    return cancellationDate <= oneWeekAgo;
  };

  // const handleOrderSubmit = (values, { resetForm }) => {
  //   // Check if the bill amount is less than the total paid amount
  //   console.log(
  //     "ssss",
  //     mainFormik?.values?.bill_amount,
  //     totalPaidAmount,
  //     sumOfAmount,
  //     billAmount
  //   );
  //   if (mainFormik?.values?.bill_amount < totalPaidAmount) {
  //     alert("Bill amount is now less than paid amount");
  //     return;
  //   }

  //   if (breakupDetail?.length === 0) {
  //     dispatch(
  //       showSnackbar({
  //         message: "Please add at least one breakup detail before submitting.",
  //         autoHideDuration: 3000,
  //         anchorOrigin: {
  //           vertical: "top",
  //           horizontal: "right",
  //         },
  //         variant: "warning",
  //       })
  //     );
  //     return;
  //   }

  //   const totalBreakupPrice = breakupDetail.reduce(
  //     (total, breakup) => total + parseFloat(breakup.price),
  //     0
  //   );

  //   if (values.deltype === "2nd Delivery Date") {
  //     const isFirstDeliveryChosen = orderDetails.some(
  //       (entry) => entry.deltype === "1st Delivery Date"
  //     );
  //     if (!isFirstDeliveryChosen) {
  //       dispatch(
  //         showSnackbar({
  //           message:
  //             "You must choose the '1st Delivery Date' before selecting '2nd Delivery Date'",
  //           autoHideDuration: 3000,
  //           anchorOrigin: {
  //             vertical: "top",
  //             horizontal: "right",
  //           },
  //           variant: "warning",
  //         })
  //       );
  //       setShowSecondDeliveryDate(false);
  //       return;
  //     }
  //   }

  //   if (editIndex !== null) {
  //     // Perform the bill amount check again in edit mode
  //     console.log(
  //       "ssss",
  //       mainFormik?.values?.bill_amount,
  //       totalPaidAmount,
  //       sumOfAmount
  //     );
  //     if (mainFormik?.values?.bill_amount < totalPaidAmount) {
  //       alert("Bill amount is now less than paid amount");
  //       return;
  //     }

  //     const updatedOrderDetails = [...orderDetails];
  //     updatedOrderDetails[editIndex] = {
  //       ...updatedOrderDetails[editIndex],
  //       particular: values.dressType,
  //       color: values.colors,
  //       lining: values.lining,
  //       qty: values.qty,
  //       deltype: values.deltype,
  //       master: values.master,
  //       tailor: values.tailor,
  //       attachment: roughDiagram,
  //       breakupDetails: [...breakupDetail],
  //       amount: totalBreakupPrice * parseFloat(values.qty),
  //       checked: values?.headId ? true : false,
  //     };

  //     const isFirstDeliveryChosen = updatedOrderDetails.some(
  //       (detail) => detail.deltype === "1st Delivery Date"
  //     );
  //     const isSecondDeliveryChosen = updatedOrderDetails.some(
  //       (detail) => detail.deltype === "2nd Delivery Date"
  //     );

  //     if (!isFirstDeliveryChosen && isSecondDeliveryChosen) {
  //       dispatch(
  //         showSnackbar({
  //           message:
  //             "You must choose the '1st Delivery Date' before selecting '2nd Delivery Date'",
  //           autoHideDuration: 3000,
  //           anchorOrigin: {
  //             vertical: "top",
  //             horizontal: "right",
  //           },
  //           variant: "warning",
  //         })
  //       );
  //       return;
  //     }

  //     setOrderDetails(updatedOrderDetails);
  //     setFirstDeliveryChosen(isFirstDeliveryChosen);
  //     setShowSecondDeliveryDate(isSecondDeliveryChosen);
  //     setEditIndex(null);
  //   } else {
  //     if (orderDetails?.length >= 10) {
  //       dispatch(
  //         showSnackbar({
  //           message: "Only 10 Order Details allowed",
  //           autoHideDuration: 3000,
  //           anchorOrigin: {
  //             vertical: "top",
  //             horizontal: "right",
  //           },
  //           variant: "warning",
  //         })
  //       );
  //       setBreakupDetail([]);
  //       orderFormik?.setFieldValue("colors", "");
  //       orderFormik?.setFieldValue("lining", "");
  //       orderFormik?.setFieldValue("dressType", "");
  //       orderFormik?.setFieldValue("qty", "");
  //       orderFormik.setFieldTouched("colors", false);
  //       orderFormik.setFieldTouched("lining", false);
  //       orderFormik.setFieldTouched("dressType", false);
  //       orderFormik.setFieldTouched("qty", false);
  //       return;
  //     }

  //     if (values.deltype === "2nd Delivery Date") {
  //       const isFirstDeliveryChosen = orderDetails.some(
  //         (entry) => entry.deltype === "1st Delivery Date"
  //       );
  //       if (!isFirstDeliveryChosen) {
  //         dispatch(
  //           showSnackbar({
  //             message:
  //               "You must choose the '1st Delivery Date' before selecting '2nd Delivery Date'",
  //             autoHideDuration: 3000,
  //             anchorOrigin: {
  //               vertical: "top",
  //               horizontal: "right",
  //             },
  //             variant: "warning",
  //           })
  //         );
  //         return;
  //       }
  //     }

  //     const newOrderDetail = {
  //       id: orderDetails.length + 1,
  //       particular: values.dressType,
  //       color: values.colors,
  //       lining: values.lining,
  //       qty: values.qty,
  //       deltype: values.deltype,
  //       master: values.master,
  //       tailor: values.tailor,
  //       attachment: roughDiagram,
  //       breakupDetails: [...breakupDetail],
  //       amount: totalBreakupPrice * parseFloat(values.qty),
  //       checked: values?.headId ? true : false,
  //     };

  //     const updatedOrderDetails = [...orderDetails, newOrderDetail];
  //     setOrderDetails(updatedOrderDetails);
  //     setRoughDiagram();
  //     mainFormik.setFieldValue("orddate", moment());

  //     const isFirstDeliveryChosen = updatedOrderDetails.some(
  //       (detail) => detail.deltype === "1st Delivery Date"
  //     );
  //     const isSecondDeliveryChosen = updatedOrderDetails.some(
  //       (detail) => detail.deltype === "2nd Delivery Date"
  //     );

  //     setFirstDeliveryChosen(isFirstDeliveryChosen);
  //     setShowSecondDeliveryDate(isSecondDeliveryChosen);
  //   }

  //   if (values.deltype === "1st Delivery Date") {
  //     setFirstDeliveryChosen(true);
  //   }

  //   setBreakupDetail([]);
  //   orderFormik?.setFieldValue("colors", "");
  //   orderFormik?.setFieldValue("lining", "");
  //   orderFormik?.setFieldValue("dressType", "");
  //   orderFormik?.setFieldValue("qty", "");
  //   orderFormik.setFieldTouched("colors", false);
  //   orderFormik.setFieldTouched("lining", false);
  //   orderFormik.setFieldTouched("dressType", false);
  //   orderFormik.setFieldTouched("qty", false);
  // };
  // useEffect(() => {
  //   if (
  //     (latestOrder?.bill_amount || mainFormik?.values?.bill_amount) <
  //       totalPaidAmount &&
  //     mode === "edit"
  //   ) {
  //     dispatch(
  //       showSnackbar({
  //         message: "Bill amount is now less than paid amount",
  //         autoHideDuration: 3000,
  //         anchorOrigin: {
  //           vertical: "top",
  //           horizontal: "right",
  //         },
  //         variant: "warning",
  //       })
  //     );
  //     // alert("Bill amount is now less than paid amount");
  //     return; // Exit the function without proceeding further
  //   }
  // }, [
  //   latestOrder?.bill_amount,
  //   mainFormik?.values?.bill_amount,
  //   totalPaidAmount,
  // ]);
  const handleOrderSubmit = (values, { resetForm }) => {
    // Check if the bill amount condition is met

    if (breakupDetail?.length === 0) {
      dispatch(
        showSnackbar({
          message: "Please add at least one breakup detail before submitting.",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "warning",
        })
      );
      return;
    }

    const totalBreakupPrice = breakupDetail.reduce(
      (total, breakup) => total + parseFloat(breakup.price),
      0
    );

    if (values.deltype === "2nd Delivery Date") {
      const isFirstDeliveryChosen = orderDetails.some(
        (entry) => entry.deltype === "1st Delivery Date"
      );
      if (!isFirstDeliveryChosen) {
        dispatch(
          showSnackbar({
            message:
              "You must choose the '1st Delivery Date' before selecting '2nd Delivery Date'",
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "warning",
          })
        );
        setShowSecondDeliveryDate(false);
        return;
      }
    }

    if (editIndex !== null) {
      // Check if the bill amount condition is met for edited order details
      // if (
      //   (latestOrder?.bill_amount || mainFormik?.values?.bill_amount) <
      //     totalPaidAmount &&
      //   mode === "edit"
      // ) {
      //   dispatch(
      //     showSnackbar({
      //       message: "Bill amount is now less than paid amount",
      //       autoHideDuration: 3000,
      //       anchorOrigin: {
      //         vertical: "top",
      //         horizontal: "right",
      //       },
      //       variant: "warning",
      //     })
      //   );
      //   // alert("Bill amount is now less than paid amount");
      //   return; // Exit the function without proceeding further
      // }

      const updatedOrderDetails = [...orderDetails];
      updatedOrderDetails[editIndex] = {
        ...updatedOrderDetails[editIndex],
        particular: values.dressType,
        color: values.colors,
        lining: values.lining,
        qty: values.qty,
        deltype: values.deltype,
        master: values.master,
        tailor: values.tailor,
        attachment: roughDiagram,
        breakupDetails: [...breakupDetail],
        amount: totalBreakupPrice * parseFloat(values.qty),
        checked: values?.headId ? true : false,
      };

      const isFirstDeliveryChosen = updatedOrderDetails.some(
        (detail) => detail.deltype === "1st Delivery Date"
      );
      const isSecondDeliveryChosen = updatedOrderDetails.some(
        (detail) => detail.deltype === "2nd Delivery Date"
      );

      if (!isFirstDeliveryChosen && isSecondDeliveryChosen) {
        dispatch(
          showSnackbar({
            message:
              "You must choose the '1st Delivery Date' before selecting '2nd Delivery Date'",
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "warning",
          })
        );
        return;
      }

      setOrderDetails(updatedOrderDetails);
      setFirstDeliveryChosen(isFirstDeliveryChosen);
      setShowSecondDeliveryDate(isSecondDeliveryChosen);
      setEditIndex(null);
    } else {
      if (orderDetails?.length >= 10) {
        dispatch(
          showSnackbar({
            message: "Only 10 Order Details allowed",
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "warning",
          })
        );
        setBreakupDetail([]);
        orderFormik?.setFieldValue("colors", "");
        orderFormik?.setFieldValue("lining", "");
        orderFormik?.setFieldValue("dressType", "");
        orderFormik?.setFieldValue("qty", "");
        orderFormik.setFieldTouched("colors", false);
        orderFormik.setFieldTouched("lining", false);
        orderFormik.setFieldTouched("dressType", false);
        orderFormik.setFieldTouched("qty", false);
        return;
      }
      if (values.deltype === "2nd Delivery Date") {
        const isFirstDeliveryChosen = orderDetails.some(
          (entry) => entry.deltype === "1st Delivery Date"
        );
        if (!isFirstDeliveryChosen) {
          dispatch(
            showSnackbar({
              message:
                "You must choose the '1st Delivery Date' before selecting '2nd Delivery Date'",
              autoHideDuration: 3000,
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "warning",
            })
          );
          return;
        }
      }

      const newOrderDetail = {
        id: orderDetails.length + 1,
        particular: values.dressType,
        color: values.colors,
        lining: values.lining,
        qty: values.qty,
        deltype: values.deltype,
        master: values.master,
        tailor: values.tailor,
        attachment: roughDiagram,
        breakupDetails: [...breakupDetail],
        amount: totalBreakupPrice * parseFloat(values.qty),
        checked: values?.headId ? true : false,
      };

      const updatedOrderDetails = [...orderDetails, newOrderDetail];
      setOrderDetails(updatedOrderDetails);
      setRoughDiagram();
      mainFormik.setFieldValue("orddate", moment());

      const isFirstDeliveryChosen = updatedOrderDetails.some(
        (detail) => detail.deltype === "1st Delivery Date"
      );
      const isSecondDeliveryChosen = updatedOrderDetails.some(
        (detail) => detail.deltype === "2nd Delivery Date"
      );

      setFirstDeliveryChosen(isFirstDeliveryChosen);
      setShowSecondDeliveryDate(isSecondDeliveryChosen);
    }

    if (values.deltype === "1st Delivery Date") {
      setFirstDeliveryChosen(true);
    }

    setBreakupDetail([]);
    orderFormik?.setFieldValue("colors", "");
    orderFormik?.setFieldValue("lining", "");
    orderFormik?.setFieldValue("dressType", "");
    orderFormik?.setFieldValue("qty", "");
    orderFormik.setFieldTouched("colors", false);
    orderFormik.setFieldTouched("lining", false);
    orderFormik.setFieldTouched("dressType", false);
    orderFormik.setFieldTouched("qty", false);
  };
  const handleOrderEdit = (index, detail) => {
    // if (
    //   (latestOrder?.bill_amount || mainFormik?.values?.bill_amount) <
    //     totalPaidAmount &&
    //   mode === "edit"
    // ) {
    //   dispatch(
    //     showSnackbar({
    //       message: "Bill amount is now less than paid amount",
    //       autoHideDuration: 3000,
    //       anchorOrigin: {
    //         vertical: "top",
    //         horizontal: "right",
    //       },
    //       variant: "warning",
    //     })
    //   );
    //   // alert("Bill amount is now less than paid amount");
    //   return; // Exit the function without proceeding further
    // }
    const isFirstDeliveryChosen = orderDetails.some(
      (entry) => entry.deltype === "1st Delivery Date"
    );

    // if (detail.deltype === "2nd Delivery Date" && !isFirstDeliveryChosen) {
    //   dispatch(
    //     showSnackbar({
    //       message:
    //         "You must choose the '1st Delivery Date' before selecting '2nd Delivery Date'",
    //       autoHideDuration: 3000,
    //       anchorOrigin: {
    //         vertical: "top",
    //         horizontal: "right",
    //       },
    //       variant: "warning",
    //     })
    //   );
    //   return;
    // }

    setEditIndex(index);

    orderFormik.setValues({
      dressType: detail.particular,
      colors: detail.color,
      lining: detail.lining,
      qty: detail.qty,
      deltype: detail.deltype,
      master: detail.master,
      tailor: detail.tailor,
    });

    const relatedBreakupDetails = detail.breakupDetails || [];
    setBreakupDetail(relatedBreakupDetails);

    setSelectedOrderDetail(detail);
    setOpenBreakupModal(true);
    if (mode === "edit") {
      const hasSecondDeliveryDateInOriginal = orderDetails.some(
        (head) => head.deltype === "2nd Delivery Date"
      );
      setEditSecondDeliveryDate(hasSecondDeliveryDateInOriginal);
    }
  };

  // const handleOrderDelete = (index) => {
  //   const deletedOrder = orderDetails[index];
  //   const deletedOrderId = deletedOrder.id;
  //   const isSecondDeliveryDeleted =
  //     deletedOrder.deltype === "2nd Delivery Date";

  //   // Check if the deleted order is a first delivery date
  //   const isFirstDeliveryDeleted = deletedOrder.deltype === "1st Delivery Date";

  //   // Check if the order to delete is the last entry
  //   const isLastEntry = orderDetails.length === 1;

  //   // If it's the last entry and the user is in edit mode, show an alert and return early to prevent deletion
  //   if (isLastEntry && mode === "edit") {
  //     dispatch(
  //       showSnackbar({
  //         message:
  //           "You can't delete all the order details. If you need to cancel this order, please do so.",
  //         autoHideDuration: 3000,
  //         anchorOrigin: {
  //           vertical: "top",
  //           horizontal: "right",
  //         },
  //         variant: "warning",
  //       })
  //     );
  //     // alert(
  //     //   "You can't delete all the order details. If you need to cancel this order, please do so."
  //     // );
  //     return;
  //   }

  //   // If the deleted order is a first delivery date, show an alert and return early to prevent deletion
  //   if (isFirstDeliveryDeleted) {
  //     dispatch(
  //       showSnackbar({
  //         message: "After deletion, the first delivery date is required.",
  //         autoHideDuration: 3000,
  //         anchorOrigin: {
  //           vertical: "top",
  //           horizontal: "right",
  //         },
  //         variant: "warning",
  //       })
  //     );
  //     // alert("After deletion, the first delivery date is required.");
  //     return;
  //   }

  //   // Continue with deletion logic only if it's not a first delivery date and not the last entry
  //   if (isSecondDeliveryDeleted) {
  //     setShowSecondDeliveryDate(isSecondDeliveryDeleted);
  //   }

  //   const updatedBreakupDetails = breakupDetail.filter(
  //     (breakup) => breakup.orderId !== deletedOrderId
  //   );
  //   setBreakupDetail(updatedBreakupDetails);

  //   if (index === editIndex) {
  //     setEditIndex(null);
  //     setOpenBreakupModal(false);
  //   }

  //   const updatedOrderDetails = orderDetails.filter((_, i) => i !== index);
  //   const reassignedOrderDetails = updatedOrderDetails.map((order, i) => ({
  //     ...order,
  //     id: i + 1,
  //   }));

  //   const allSecondDeliveryDeleted = reassignedOrderDetails.every(
  //     (order) => order.deltype !== "2nd Delivery Date"
  //   );

  //   setOrderDetails(reassignedOrderDetails);
  //   if (allSecondDeliveryDeleted && isSecondDeliveryDeleted) {
  //     setShowSecondDeliveryDate(true);
  //   }
  // };

  const handleOrderDelete = (index) => {
    // Check the additional condition for bill amount and paid amount
    // if (
    //   (latestOrder?.bill_amount || mainFormik?.values?.bill_amount) <
    //     totalPaidAmount &&
    //   mode === "edit"
    // ) {
    //   dispatch(
    //     showSnackbar({
    //       message: "Bill amount is now less than paid amount",
    //       autoHideDuration: 3000,
    //       anchorOrigin: {
    //         vertical: "top",
    //         horizontal: "right",
    //       },
    //       variant: "warning",
    //     })
    //   );
    //   // alert("Bill amount is now less than paid amount");
    //   return; // Exit the function without proceeding further
    // }

    // Retrieve the order details and related information
    const deletedOrder = orderDetails[index];
    const deletedOrderId = deletedOrder.id;
    const isSecondDeliveryDeleted =
      deletedOrder.deltype === "2nd Delivery Date";
    const isFirstDeliveryDeleted = deletedOrder.deltype === "1st Delivery Date";
    const isLastEntry = orderDetails.length === 1;

    // Check for deletion constraints
    if (isLastEntry && mode === "edit") {
      dispatch(
        showSnackbar({
          message:
            "You can't delete all the order details. If you need to cancel this order, please do so.",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "warning",
        })
      );
      return;
    }

    // if (isFirstDeliveryDeleted) {
    //   dispatch(
    //     showSnackbar({
    //       message: "After deletion, the first delivery date is required.",
    //       autoHideDuration: 3000,
    //       anchorOrigin: {
    //         vertical: "top",
    //         horizontal: "right",
    //       },
    //       variant: "warning",
    //     })
    //   );
    //   return;
    // }

    // Proceed with deletion logic
    const updatedBreakupDetails = breakupDetail.filter(
      (breakup) => breakup.orderId !== deletedOrderId
    );
    setBreakupDetail(updatedBreakupDetails);

    if (index === editIndex) {
      setEditIndex(null);
      setOpenBreakupModal(false);
    }

    const updatedOrderDetails = orderDetails.filter((_, i) => i !== index);
    const reassignedOrderDetails = updatedOrderDetails.map((order, i) => ({
      ...order,
      id: i + 1,
    }));

    // Update order details state
    setOrderDetails(reassignedOrderDetails);

    // Check if there are any remaining second delivery date orders
    const hasSecondDeliveryDate = reassignedOrderDetails.some(
      (order) => order.deltype === "2nd Delivery Date"
    );

    // Debugging: Log the state to verify
    // 2console.log("Updated Order Details:", reassignedOrderDetails);
    // 2console.log("Has Second Delivery Date:", hasSecondDeliveryDate);

    // Update visibility of the second delivery date field
    setShowSecondDeliveryDate(hasSecondDeliveryDate);

    // Check if the "1st Delivery Date" is still present
    const hasFirstDeliveryDate = reassignedOrderDetails.some(
      (order) => order.deltype === "1st Delivery Date"
    );

    if (!hasFirstDeliveryDate) {
      dispatch(
        showSnackbar({
          message:
            "All '1st Delivery Date' entries have been deleted. Please add at least one.",
          autoHideDuration: 5000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "warning",
        })
      );
    }

    // Special handling for edit mode, if needed
  };
  // 2console.log(
  //   "samplees",
  //   orderDetails.some((head) => head.deltype === "1st Delivery Date")
  // );

  const shouldShowDatePicker = showSecondDeliveryDate || editSecondDeliveryDate;
  // 2console.log("Updated Order Details:", showSecondDeliveryDate);
  // 2console.log("Has Second Delivery Date:", editSecondDeliveryDate);
  // 2console.log("Has Second Delivery Date:", shouldShowDatePicker);
  // 2console.log("Has Second Delivery Date:", orderDetails);
  useEffect(() => {
    if (mode === "edit") {
      const hasSecondDeliveryDateInOriginal = orderDetails.some(
        (head) => head.deltype === "2nd Delivery Date"
      );
      setEditSecondDeliveryDate(hasSecondDeliveryDateInOriginal);
    }
  }, [orderDetails]);

  const orderFormik = useFormik({
    initialValues: {
      dressType: null,
      colors: "",
      lining: "",
      qty: "",
      deltype: "",
      master: "",
      tailor: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      dressType: Yup.string()
        .max(25, "Must be 25 characters or less")
        .required("Dress Type Required"),
      colors: Yup.string()
        .max(25, "Must be 25 characters or less")
        .required("Colors Required"),
      lining: Yup.string().max(25, "Must be 25 characters or less"),
      qty: Yup.number()
        .typeError("Must be a number")
        .required("Quantity is required")
        .integer("Must be an integer")
        .positive("Must be a positive number")
        .max(99999, "Must be at most 5 digits"),
      deltype: Yup.string().required("Delivery Type is required"),
      master: Yup.string()
        .max(25, "Must be 25 characters or less")
        .matches(/^[A-Za-z\s]+$/, "Invalid"),
      tailor: Yup.string()
        .max(25, "Must be 25 characters or less")
        .matches(/^[A-Za-z\s]+$/, "Invalid"),
    }),
    onSubmit: handleOrderSubmit,
  });
  const billAmount = mainFormik?.values?.bill_amount;
  // {
  //  2 console.log("ss", mainFormik?.values?.bill_amount, totalPaidAmount);
  // }
  // const previousBillAmount = useRef(mainFormik?.values?.bill_amount);

  // const [warningShown, setWarningShown] = useState(false);

  useEffect(() => {
    // Define the checkAmount function
    const checkAmount = () => {
      // Ensure bill_amount is defined and not zero
      if (
        mainFormik?.values?.bill_amount < totalPaidAmount &&
        mode === "edit" &&
        open &&
        mainFormik?.values?.bill_amount < 0
      ) {
        dispatch(
          showSnackbar({
            message: "Bill amount is now less than paid amount",
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "warning",
          })
        );
      }
    };

    // Set a timeout to execute the checkAmount function after 3000ms
    const timeoutId = setTimeout(() => {
      checkAmount();
    }, 3000);

    // Cleanup function to clear the timeout if the component unmounts or dependencies change
    return () => clearTimeout(timeoutId);
  }, [mainFormik?.values?.bill_amount, totalPaidAmount, mode, dispatch, open]);

  // useEffect(() => {
  //   const checkAmount = () => {
  //     if (mainFormik?.values?.bill_amount < totalPaidAmount) {
  //       alert("Bill amount is less than or equal to paid amount");
  //     }
  //   };
  //   const intervalId = setInterval(checkAmount, 1000);
  //   return () => clearInterval(intervalId);
  // }, [mainFormik?.values?.bill_amount, totalPaidAmount]);

  const totalPayments = parsedPaymentDetails.reduce(
    (sum, data) => sum + (data?.amount || 0),
    0
  );
  const totalDiscounts = parsedPaymentDetails.reduce(
    (sum, data) => sum + (data?.discount || 0),
    0
  );
  const dueAmountCreate = Number(mainFormik.values.due_amount); // Convert to number
  const resultCreate = Number.isNaN(dueAmountCreate) ? 0 : dueAmountCreate;
  // console.log(
  //   "dueAmount",
  //   parseFloat(
  //     (mainFormik?.values?.bill_amount || 0) -
  //       totalPayments -
  //       totalDiscounts -
  //       (mainFormik?.values?.advance || 0) -
  //       (mainFormik?.values?.discount || 0)
  //   ),
  //   mainFormik?.values?.due_amount
  // );
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-title"
        disableBackdropClick={true}
        BackdropProps={{
          onClick: (event) => {
            event.stopPropagation();
          },
        }}
      >
        <Box sx={{ ...style, display: "flex", flexDirection: "column", mb: 2 }}>
          <Grid container alignItems="center">
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" component="h2" gutterBottom>
                {mode === "view"
                  ? "Order View"
                  : mode === "edit"
                  ? "Order Manage"
                  : mode === "orderhistory"
                  ? "Order History"
                  : "Order Creation"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} container justifyContent="flex-end">
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Box mt={2}>
            <Grid container spacing={2}>
              {mode === "view" || mode === "edit" || mode === "print" ? null : (
                <Grid item xs={12} sm={6} lg={4}>
                  <Autocomplete
                    fullWidth
                    options={mobileNumberOptions}
                    value={formik?.values?.mobileNumber}
                    onChange={handleMobileNumberChange}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      if (params.inputValue !== "") {
                        filtered.push({
                          inputValue: params.inputValue,
                          label: `Add "${params.inputValue}"`,
                        });
                      }

                      return filtered;
                    }}
                    getOptionLabel={(option) => {
                      // Value when selected or created
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.user?.mobile_number;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Mobile Number"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>
            <Grid container spacing={2} mt={2}>
              {customerNameOptions?.length > 1 ? (
                <>
                  <Grid item xs={12} sm={6} lg={4}>
                    <Autocomplete
                      options={customerNameOptions || []}
                      getOptionLabel={(option) =>
                        option?.user?.name && option?.cust_id
                          ? `${option.user.name}/${option.cust_id}`
                          : ""
                      }
                      name="customerName"
                      id="customerName"
                      value={formik?.values?.customerName || null}
                      onChange={(event, newValue) => {
                        formik.setFieldValue("customerName", newValue);
                        setCustomerId(newValue.id);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="customerName"
                          label="Customer Name"
                          variant="outlined"
                          error={
                            formik.touched.customerName &&
                            Boolean(formik.errors.customerName)
                          }
                          helperText={
                            formik.touched.customerName &&
                            formik.errors.customerName
                          }
                          InputProps={{
                            ...params.InputProps,
                            readOnly: isReadOnly,
                          }}
                        />
                      )}
                    />
                  </Grid>
                </>
              ) : null}
              {mode === "view" ? (
                <Grid container spacing={2} mx={1} mt={2}>
                  <Grid item xs={12} sm={6} lg={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={6} lg={6}>
                        <Typography variant="subtitle2" component="p">
                          <strong>Customer ID:</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} lg={6}>
                        <Typography variant="subtitle2" component="p">
                          {latestOrder?.customer?.cust_id}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={6} lg={6}>
                        <Typography variant="subtitle2" component="p">
                          <strong>Order ID:</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} lg={6}>
                        <Typography variant="subtitle2" component="p">
                          {latestOrder?.order_id}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={6} lg={6}>
                        <Typography variant="subtitle2" component="p">
                          <strong>Customer Name:</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} lg={6}>
                        {latestOrder?.customer?.user?.name && (
                          <Typography variant="subtitle2" component="p">
                            {latestOrder?.customer?.user?.name}
                            {latestOrder?.customer?.ref_name &&
                              `(${latestOrder?.customer?.ref_name})`}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* <Grid item xs={12} sm={6} lg={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={6} lg={6}>
                        <Typography variant="subtitle2" component="p">
                          <strong>Ref Name:</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} lg={6}>
                        <Typography variant="subtitle2" component="p">
                          {latestOrder?.customer?.ref_name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid> */}
                  <Grid item xs={12} sm={6} lg={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={6} lg={6}>
                        <Typography variant="subtitle2" component="p">
                          <strong>Location:</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} lg={6}>
                        <Typography variant="subtitle2" component="p">
                          {latestOrder?.customer?.location}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={6} lg={6}>
                        <Typography variant="subtitle2" component="p">
                          <strong>Mobile Number:</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} lg={6}>
                        <Typography variant="subtitle2" component="p">
                          {latestOrder?.customer?.user?.mobile_number}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={6} lg={6}>
                        <Typography variant="subtitle2" component="p">
                          <strong>Order Date:</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} lg={6}>
                        <Typography variant="subtitle2" component="p">
                          {latestOrder?.order_date
                            ? new Date(
                                latestOrder.order_date
                              ).toLocaleDateString("en-GB")
                            : ""}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={6} lg={6}>
                        <Typography variant="subtitle2" component="p">
                          <strong>Payment Date:</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} lg={6}>
                        <Typography variant="subtitle2" component="p">
                          {latestOrder?.payment_date
                            ? new Date(
                                latestOrder.payment_date
                              ).toLocaleDateString("en-GB")
                            : ""}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={6} lg={6}>
                        <Typography variant="subtitle2" component="p">
                          <strong>Payment Mode:</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} lg={6}>
                        <Typography variant="subtitle2" component="p">
                          {latestOrder?.payment_mode}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={6} lg={6}>
                        <Typography variant="subtitle2" component="p">
                          <strong>1st Delivery Date:</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} lg={6}>
                        <Typography variant="subtitle2" component="p">
                          {latestOrder?.delivery_date
                            ? moment(latestOrder?.delivery_date).format(
                                "DD/MM/YYYY"
                              )
                            : ""}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={6} lg={6}>
                        <Typography variant="subtitle2" component="p">
                          {latestOrder?.is_second_delivery_date_req &&
                            shouldShowDatePicker && (
                              <strong>2nd Delivery Date:</strong>
                            )}
                        </Typography>
                      </Grid>

                      <Grid item xs={6} sm={6} lg={6}>
                        <Typography variant="subtitle2" component="p">
                          {latestOrder?.second_delivery_date &&
                          shouldShowDatePicker
                            ? moment(latestOrder?.second_delivery_date).format(
                                "DD/MM/YYYY"
                              )
                            : ""}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}

              {mode === "view" ? null : customerNameOptions?.length === 0 ? (
                <Grid container spacing={2} mx={1} mt={2}>
                  <Grid item xs={12} sm={6} lg={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={6} lg={6}>
                        <Typography variant="subtitle2" component="p">
                          <strong>Customer ID:</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} lg={6}>
                        <Typography variant="subtitle2" component="p">
                          {customerData?.cust_id}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={6} lg={6}>
                        <Typography variant="subtitle2" component="p">
                          <strong>Customer Name:</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} lg={6}>
                        {customerData?.user?.name && (
                          <Typography variant="subtitle2" component="p">
                            {customerData?.user?.name}
                            {customerData?.ref_name &&
                              `(${customerData?.ref_name})`}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* <Grid item xs={12} sm={6} lg={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={6} lg={6}>
                        <Typography variant="subtitle2" component="p">
                          <strong>Ref Name:</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} lg={6}>
                        <Typography variant="subtitle2" component="p">
                          {customerData?.ref_name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid> */}
                  <Grid item xs={12} sm={6} lg={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={6} lg={6}>
                        <Typography variant="subtitle2" component="p">
                          <strong>Location:</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} lg={6}>
                        <Typography variant="subtitle2" component="p">
                          {customerData?.location}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={2} mx={1} mt={2}>
                  <Grid item xs={12} sm={6} lg={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={6} lg={6}>
                        <Typography variant="subtitle2" component="p">
                          <strong>Customer ID:</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} lg={6}>
                        <Typography variant="subtitle2" component="p">
                          {mode === "print"
                            ? formik?.values?.customerName?.cust_id
                            : latestOrder?.customer?.cust_id ||
                              formik?.values?.customerName?.cust_id}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  {mode === "print" ||
                    (mode === "edit" && (
                      <Grid item xs={12} sm={6} lg={3}>
                        <Grid container spacing={2}>
                          <Grid item xs={6} sm={6} lg={6}>
                            <Typography variant="subtitle2" component="p">
                              <strong>Order ID:</strong>
                            </Typography>
                          </Grid>
                          <Grid item xs={6} sm={6} lg={6}>
                            <Typography variant="subtitle2" component="p">
                              {latestOrder?.order_id}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  <Grid item xs={12} sm={6} lg={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={6} lg={6}>
                        <Typography variant="subtitle2" component="p">
                          <strong>Customer Name:</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} lg={6}>
                        {formik?.values?.customerName?.user?.name && (
                          <Typography variant="subtitle2" component="p">
                            {mode === "print"
                              ? `${formik.values.customerName.user.name}${
                                  formik.values.customerName.ref_name
                                    ? ` (${formik.values.customerName.ref_name})`
                                    : ` (${latestOrder?.customer?.ref_name})`
                                }`
                              : `${
                                  latestOrder?.customer?.user?.name ||
                                  formik.values.customerName.user.name
                                }${
                                  formik.values.customerName.ref_name &&
                                  `(${formik.values.customerName.ref_name})`
                                }`}
                            {/* {2console.log("names", latestOrder)} */}
                          </Typography>
                        )}
                        {mode === "edit" && (
                          <Typography variant="subtitle2" component="p">
                            {latestOrder?.customer?.user?.name}{" "}
                            {latestOrder?.customer?.ref_name &&
                              `(${latestOrder?.customer?.ref_name})`}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* <Grid item xs={12} sm={6} lg={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={6} lg={6}>
                        <Typography variant="subtitle2" component="p">
                          <strong>Ref Name:</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} lg={6}>
                        <Typography variant="subtitle2" component="p">
                          {mode === "print"
                            ? formik?.values?.customerName?.ref_name
                            : latestOrder?.customer?.ref_name ||
                              formik?.values?.customerName?.ref_name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid> */}

                  {mode === "print" && (
                    <Grid item xs={12} sm={6} lg={3}>
                      <Grid container spacing={2}>
                        <Grid item xs={6} sm={6} lg={6}>
                          <Typography variant="subtitle2" component="p">
                            <strong>Mobile Number:</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} lg={6}>
                          <Typography variant="subtitle2" component="p">
                            {formik?.values?.customerName?.user
                              ?.mobile_number ||
                              latestOrder?.customer?.user?.mobile_number ||
                              formik?.values?.customerName?.user?.mobile_number}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6} lg={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={6} lg={6}>
                        <Typography variant="subtitle2" component="p">
                          <strong>Location:</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} lg={6}>
                        <Typography variant="subtitle2" component="p">
                          {mode === "print"
                            ? formik?.values?.customerName?.location
                            : latestOrder?.customer?.location ||
                              formik?.values?.customerName?.location}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  {mode === "print" && (
                    <>
                      <Grid item xs={12} sm={6} lg={3}>
                        <Grid container spacing={2}>
                          <Grid item xs={6} sm={6} lg={6}>
                            <Typography variant="subtitle2" component="p">
                              <strong>Order ID:</strong>
                            </Typography>
                          </Grid>
                          <Grid item xs={6} sm={6} lg={6}>
                            <Typography variant="subtitle2" component="p">
                              {latestOrder.order_id}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={6} lg={3}>
                        <Grid container spacing={2}>
                          <Grid item xs={6} sm={6} lg={6}>
                            <Typography variant="subtitle2" component="p">
                              <strong>Order Date:</strong>
                            </Typography>
                          </Grid>
                          <Grid item xs={6} sm={6} lg={6}>
                            <Typography variant="subtitle2" component="p">
                              {latestOrder
                                ? new Date(
                                    latestOrder.order_date
                                  ).toLocaleString("en-GB", {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    hour12: true,
                                  })
                                : ""}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {mode === "edit" ? (
                    <Grid item xs={12} sm={6} lg={3}>
                      <Grid container spacing={2}>
                        <Grid item xs={6} sm={6} lg={6}>
                          <Typography variant="subtitle2" component="p">
                            <strong>Order Date:</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} lg={6}>
                          <Typography variant="subtitle2" component="p">
                            {latestOrder
                              ? new Date(
                                  latestOrder.order_date
                                ).toLocaleDateString("en-GB")
                              : ""}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : null}
                  {mode === "edit" && (
                    <Grid item xs={12} sm={6} lg={3}>
                      <Grid container spacing={2}>
                        <Grid item xs={6} sm={6} lg={6}>
                          <Typography variant="subtitle2" component="p">
                            <strong>1st Delivery Date:</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} lg={6}>
                          <Typography variant="subtitle2" component="p">
                            {latestOrder
                              ? new Date(
                                  latestOrder.delivery_date
                                ).toLocaleDateString("en-GB")
                              : ""}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {mode === "edit" &&
                    latestOrder?.is_second_delivery_date_req &&
                    shouldShowDatePicker && (
                      <Grid item xs={12} sm={6} lg={3}>
                        <Grid container spacing={2}>
                          <Grid item xs={6} sm={6} lg={6}>
                            <Typography variant="subtitle2" component="p">
                              <strong>2nd Delivery Date:</strong>
                            </Typography>
                          </Grid>
                          <Grid item xs={6} sm={6} lg={6}>
                            <Typography variant="subtitle2" component="p">
                              {latestOrder
                                ? new Date(
                                    latestOrder.second_delivery_date
                                  ).toLocaleDateString("en-GB")
                                : ""}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                </Grid>
              )}
            </Grid>
            <Divider variant="middle" sx={divider} />
            <Grid container mt={2}>
              {mode === "edit" ||
              mode === "view" ||
              mode === "print" ? null : mode === "history" ||
                mode === "orderhistory" ? (
                <Grid item xs={12} sm={6} lg={4}>
                  <Autocomplete
                    options={orderData || []}
                    getOptionLabel={(option) => {
                      const orderId = option?.order_id
                        ? String(option.order_id)
                        : "";
                      const orderDate = option?.order_date
                        ? formatDate(option.order_date)
                        : "";
                      return `${orderId} - ${orderDate}`;
                    }}
                    name="order_id"
                    id="order_id"
                    value={selectedHistoryData}
                    onChange={handleOrderHistoryChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="order_id"
                        label="Order History"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
              ) : (
                <>
                  {!_.isEmpty(latestOrder) && (
                    <>
                      {" "}
                      {mode === "view" ? null : (
                        <>
                          <Grid item xs={12} sm={12} lg={4}>
                            <Grid container spacing={2}>
                              <Grid item xs={6} sm={4} lg={4}>
                                <Typography variant="subtitle2" component="p">
                                  <strong>Previous Order:</strong>
                                </Typography>
                              </Grid>
                              <Grid item xs={6} sm={8} lg={8}>
                                <Typography
                                  variant="subtitle2"
                                  component="span"
                                >
                                  {latestOrder?.order_id}/
                                  {latestOrder?.particulars
                                    ? _.map(
                                        latestOrder?.particulars || [],
                                        (data) => data?.particular
                                      )
                                    : ""}
                                  /
                                  {latestOrder?.order_date
                                    ? formatDate(latestOrder.order_date)
                                    : "No order date"}
                                  <InfoOutlinedIcon
                                    fontSize="small"
                                    className={classes.icon}
                                    onClick={handlehistoryOpen}
                                  />
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={6} lg={4}>
                            <Grid container spacing={2}>
                              <Grid item xs={6} sm={6} lg={4}>
                                <Typography variant="subtitle2" component="p">
                                  <strong>Total Billed:</strong>
                                </Typography>
                              </Grid>
                              <Grid item xs={6} sm={6} lg={8}>
                                <Typography
                                  variant="subtitle2"
                                  component="span"
                                >
                                  ₹ {totalBillAmount}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={6} lg={4}>
                            <Grid container spacing={2}>
                              <Grid item xs={6} sm={6} lg={4}>
                                <Typography variant="subtitle2" component="p">
                                  <strong>Total Discount:</strong>
                                </Typography>
                              </Grid>
                              <Grid item xs={6} sm={6} lg={8}>
                                <Typography
                                  variant="subtitle2"
                                  component="span"
                                >
                                  ₹ {totalDiscountAmount}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </Grid>
            {/* edit */}
            {mode === "orderhistory" ||
            mode === "view" ||
            mode === "print" ? null : (
              <Box mt={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={2}>
                    <Autocomplete
                      options={allDressType}
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return option;
                        }
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        return option.label;
                      }}
                      value={orderFormik.values.dressType}
                      onChange={(event, newValue) => {
                        let selectedValue = newValue;
                        if (newValue && newValue.inputValue) {
                          selectedValue = newValue.inputValue;
                        } else if (typeof newValue === "string") {
                          selectedValue = newValue;
                        } else if (newValue) {
                          selectedValue = newValue.label;
                        }

                        if (newValue && newValue.isNew) {
                          handleAddNewDressType(selectedValue);
                        }

                        orderFormik.setFieldValue("dressType", selectedValue);
                      }}
                      filterOptions={(options, params) => {
                        const filtered = options.filter((option) =>
                          option.label
                            .toLowerCase()
                            .includes(params.inputValue.toLowerCase())
                        );

                        if (params.inputValue !== "") {
                          filtered.push({
                            inputValue: params.inputValue,
                            label: `Add "${params.inputValue}"`,
                            isNew: true,
                          });
                        }

                        return filtered;
                      }}
                      freeSolo
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="dressType"
                          label="Dress Type"
                          variant="outlined"
                          error={
                            orderFormik.touched.dressType &&
                            Boolean(orderFormik.errors.dressType)
                          }
                          helperText={
                            orderFormik.touched.dressType &&
                            orderFormik.errors.dressType
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {params.InputProps.endAdornment}
                                {orderFormik.values.dressType?.isNew && (
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                  >
                                    New Dress Type
                                  </Typography>
                                )}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={1}>
                    <TextField
                      sx={{
                        width: {
                          xs: "100%",
                          sm: "100%",
                        },
                      }}
                      label="Colors"
                      variant="outlined"
                      id="colors"
                      name="colors"
                      placeholder="Colors"
                      onChange={orderFormik.handleChange}
                      onBlur={orderFormik?.handleBlur}
                      error={
                        orderFormik?.touched?.colors &&
                        Boolean(orderFormik?.errors?.colors)
                      }
                      helperText={
                        orderFormik?.touched?.colors &&
                        orderFormik?.errors?.colors
                      }
                      value={orderFormik.values.colors}
                      InputProps={{ readOnly: isReadOnly }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={1}>
                    <TextField
                      sx={{
                        width: {
                          xs: "100%",
                          sm: "100%",
                        },
                      }}
                      label="Lining"
                      variant="outlined"
                      id="lining"
                      name="lining"
                      placeholder="Lining"
                      onChange={orderFormik.handleChange}
                      onBlur={orderFormik?.handleBlur}
                      error={
                        orderFormik?.touched?.lining &&
                        Boolean(orderFormik?.errors?.lining)
                      }
                      helperText={
                        orderFormik?.touched?.lining &&
                        orderFormik?.errors?.lining
                      }
                      value={orderFormik.values.lining}
                      InputProps={{ readOnly: isReadOnly }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={1}>
                    <TextField
                      sx={{
                        width: {
                          xs: "100%",
                          sm: "100%",
                        },
                      }}
                      label="Qty"
                      variant="outlined"
                      id="qty"
                      name="qty"
                      placeholder="Qty"
                      onChange={orderFormik.handleChange}
                      onBlur={orderFormik?.handleBlur}
                      error={
                        orderFormik?.touched?.qty &&
                        Boolean(orderFormik?.errors?.qty)
                      }
                      helperText={
                        orderFormik?.touched?.qty && orderFormik?.errors?.qty
                      }
                      value={orderFormik.values.qty}
                      InputProps={{ readOnly: isReadOnly }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={1}>
                    <AttachModal setRoughDiagram={setRoughDiagram} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={1}>
                    <BreakUpModal
                      breakupDetail={breakupDetail}
                      setBreakupDetail={setBreakupDetail}
                      orderDetails={orderDetails}
                      editIndex={editIndex}
                      handleBreakupEdit={handleOrderEdit}
                      setSelectedOrderDetail={setSelectedOrderDetail}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <FormControl fullWidth>
                      <InputLabel id="deltype-label">Delivery Type</InputLabel>
                      <Select
                        labelId="deltype-label"
                        id="deltype"
                        name="deltype"
                        label="Delivery Type"
                        value={orderFormik.values.deltype}
                        onChange={handleDeliveryTypeChange}
                        onBlur={orderFormik.handleBlur}
                        error={
                          orderFormik.touched.deltype &&
                          Boolean(orderFormik.errors.deltype)
                        }
                        helperText={
                          orderFormik.touched.deltype &&
                          orderFormik.errors.deltype
                        }
                      >
                        <MenuItem value="1st Delivery Date">
                          1st Delivery Date
                        </MenuItem>
                        <MenuItem value="2nd Delivery Date">
                          2nd Delivery Date
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={1}>
                    <Autocomplete
                      options={allMaster}
                      getOptionLabel={(option) => option.label}
                      value={
                        orderFormik.values.master
                          ? { label: orderFormik.values.master }
                          : null
                      }
                      onChange={(event, newValue) => {
                        orderFormik.setFieldValue(
                          "master",
                          newValue ? newValue.label : ""
                        );
                        if (newValue && newValue.isNew) {
                          handleAddNewMaster(newValue.label);
                        }
                      }}
                      onBlur={orderFormik.handleBlur("master")}
                      filterOptions={(options, params) => {
                        if (!Array.isArray(options)) {
                          return [];
                        }

                        const filtered = options.filter((option) =>
                          option.label
                            .toLowerCase()
                            .includes(params.inputValue.toLowerCase())
                        );

                        if (params.inputValue !== "") {
                          filtered.push({
                            inputValue: params.inputValue,
                            label: params.inputValue,
                            isNew: true,
                          });
                        }

                        return filtered;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="master"
                          label="Master"
                          variant="outlined"
                          error={
                            orderFormik.touched.master &&
                            Boolean(orderFormik.errors.master)
                          }
                          helperText={
                            orderFormik.touched.master &&
                            orderFormik.errors.master
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={1}>
                    <Autocomplete
                      options={allTailor}
                      getOptionLabel={(option) => option.label}
                      value={
                        orderFormik.values.tailor
                          ? { label: orderFormik.values.tailor }
                          : null
                      }
                      onChange={(event, newValue) => {
                        orderFormik.setFieldValue(
                          "tailor",
                          newValue ? newValue.label : ""
                        );
                        if (newValue && newValue.isNew) {
                          handleAddNewTailor(newValue.label);
                        }
                      }}
                      onBlur={orderFormik.handleBlur("tailor")}
                      filterOptions={(options, params) => {
                        if (!Array.isArray(options)) {
                          return [];
                        }

                        const filtered = options.filter((option) =>
                          option.label
                            .toLowerCase()
                            .includes(params.inputValue.toLowerCase())
                        );

                        if (params.inputValue !== "") {
                          filtered.push({
                            inputValue: params.inputValue,
                            label: params.inputValue,
                            isNew: true,
                          });
                        }

                        return filtered;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="tailor"
                          label="Tailor"
                          variant="outlined"
                          error={
                            orderFormik.touched.tailor &&
                            Boolean(orderFormik.errors.tailor)
                          }
                          helperText={
                            orderFormik.touched.tailor &&
                            orderFormik.errors.tailor
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={1}>
                    <Button
                      type="submit"
                      variant="contained"
                      size="medium"
                      sx={{ mx: 1 }}
                      onClick={orderFormik.handleSubmit}
                    >
                      <AddOutlinedIcon />
                      Add
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            )}

            <Box mt={2}>
              <Accordion className={classes.cardRadius}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  Order Details
                </AccordionSummary>
                <AccordionDetails sx={{ overflow: "auto !important" }}>
                  <Table sx={{ width: "100%" }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ textAlign: "center" }}>
                          <strong>ID</strong>
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          <strong>Particular</strong>
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          <strong>Color</strong>
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          <strong>Lining</strong>
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          <strong>Qty</strong>
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          <strong>Delivery Date</strong>
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          <strong>Master</strong>
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          <strong>Tailor</strong>
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          <strong>Attachment</strong>
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          <strong>Breakups</strong>
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          <strong>Total Price</strong>
                        </TableCell>
                        {mode === "orderhistory" ||
                        mode === "view" ||
                        mode === "print" ||
                        mode === "edit" ? null : (
                          <TableCell sx={{ textAlign: "center" }}>
                            <strong>Action</strong>
                          </TableCell>
                        )}
                        {/* {mode === "edit" && (
                          <TableCell sx={{ textAlign: "center" }}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  size="small"
                                  name="check_all"
                                  checked={
                                    Object.keys(checkedItems).length ===
                                      orderDetails.length &&
                                    orderDetails.every(
                                      (detail) => checkedItems[detail.id]
                                    )
                                  }
                                  onChange={handleCheckAll}
                                />
                              }
                            />
                          </TableCell>
                        )}
                        {mode === "edit" && (
                          <TableCell sx={{ textAlign: "center" }}>
                            <strong>Action</strong>
                          </TableCell>
                        )} */}
                        {mode === "edit" && (
                          <TableCell sx={{ textAlign: "center" }}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  size="small"
                                  name="check_all"
                                  checked={isCheckboxChecked}
                                  onChange={handleCheckAll}
                                />
                              }
                            />
                          </TableCell>
                        )}

                        {mode === "edit" && (
                          <TableCell sx={{ textAlign: "center" }}>
                            <strong>Action</strong>
                          </TableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {mode === "orderhistory" || mode === "view"
                        ? parsedOrderData.map((head, index) => (
                            <TableRow key={index}>
                              <TableCell sx={{ textAlign: "center" }}>
                                {head.id}
                              </TableCell>
                              <TableCell sx={{ textAlign: "center" }}>
                                {head.particular}
                              </TableCell>
                              <TableCell sx={{ textAlign: "center" }}>
                                {head.color}
                              </TableCell>
                              <TableCell sx={{ textAlign: "center" }}>
                                {head.lining}
                              </TableCell>
                              <TableCell sx={{ textAlign: "center" }}>
                                {head.qty}
                              </TableCell>
                              <TableCell sx={{ textAlign: "center" }}>
                                {latestOrder
                                  ? head.deltype === "1st Delivery Date"
                                    ? latestOrder.delivery_date
                                      ? moment(
                                          latestOrder.delivery_date
                                        ).format("DD/MM/YYYY")
                                      : ""
                                    : head.deltype === "2nd Delivery Date"
                                    ? latestOrder.second_delivery_date
                                      ? moment(
                                          latestOrder.second_delivery_date
                                        ).format("DD/MM/YYYY")
                                      : ""
                                    : ""
                                  : ""}
                              </TableCell>
                              <TableCell sx={{ textAlign: "center" }}>
                                {head.master}
                              </TableCell>
                              <TableCell sx={{ textAlign: "center" }}>
                                {head.tailor}
                              </TableCell>
                              <TableCell sx={{ textAlign: "center" }}>
                                {head.attachment ? (
                                  <AttachCopy detail={head.attachment} />
                                ) : (
                                  "No image"
                                )}
                              </TableCell>
                              <TableCell sx={{ textAlign: "center" }}>
                                {head.price}{" "}
                                {head.breakupDetails &&
                                  head.breakupDetails.map((item, idx) => (
                                    <React.Fragment key={idx}>
                                      {idx > 0 && " + "} {item.price}
                                    </React.Fragment>
                                  ))}
                                <Popup
                                  historyDetail={head.breakupDetails.map(
                                    (breakup, idx) => (
                                      <React.Fragment key={idx}>
                                        {idx > 0 && " / "}
                                        {breakup.title}: {breakup.price}
                                      </React.Fragment>
                                    )
                                  )}
                                />
                              </TableCell>
                              <TableCell sx={{ textAlign: "center" }}>
                                {head.amount}
                              </TableCell>
                            </TableRow>
                          ))
                        : mode === "edit"
                        ? orderDetails.map((head, index) => (
                            <TableRow key={head.id || index}>
                              <TableCell sx={{ textAlign: "center" }}>
                                {head.id}
                              </TableCell>
                              <TableCell sx={{ textAlign: "center" }}>
                                {head.particular}
                              </TableCell>
                              <TableCell sx={{ textAlign: "center" }}>
                                {head.color}
                              </TableCell>
                              <TableCell sx={{ textAlign: "center" }}>
                                {head.lining}
                              </TableCell>
                              <TableCell sx={{ textAlign: "center" }}>
                                {head.qty}
                              </TableCell>
                              <TableCell sx={{ textAlign: "center" }}>
                                {latestOrder
                                  ? head.deltype === "1st Delivery Date"
                                    ? latestOrder.delivery_date
                                      ? moment(
                                          latestOrder.delivery_date
                                        ).format("DD/MM/YYYY")
                                      : ""
                                    : head.deltype === "2nd Delivery Date"
                                    ? latestOrder.second_delivery_date
                                      ? moment(
                                          latestOrder.second_delivery_date
                                        ).format("DD/MM/YYYY")
                                      : ""
                                    : ""
                                  : ""}
                              </TableCell>
                              <TableCell sx={{ textAlign: "center" }}>
                                {head.master}
                              </TableCell>
                              <TableCell sx={{ textAlign: "center" }}>
                                {head.tailor}
                              </TableCell>
                              <TableCell sx={{ textAlign: "center" }}>
                                {head.attachment ? (
                                  <AttachCopy detail={head.attachment} />
                                ) : (
                                  "No image"
                                )}
                              </TableCell>
                              <TableCell sx={{ textAlign: "center" }}>
                                {head.price}
                                {head.breakupDetails &&
                                  head.breakupDetails.map((item, idx) => (
                                    <React.Fragment key={idx}>
                                      {idx > 0 && " + "} {item.price}
                                    </React.Fragment>
                                  ))}
                                <Popup
                                  historyDetail={head.breakupDetails.map(
                                    (breakup, idx) => (
                                      <React.Fragment key={idx}>
                                        {idx > 0 && " / "}
                                        {breakup.title}: {breakup.price}
                                      </React.Fragment>
                                    )
                                  )}
                                />
                              </TableCell>
                              <TableCell sx={{ textAlign: "center" }}>
                                {head.amount}
                              </TableCell>
                              <TableCell>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      size="small"
                                      name={`checkbox-${head.id}`}
                                      checked={checkedItems[head.id] || false}
                                      onChange={() =>
                                        handleCheckboxChange(head.id)
                                      }
                                    />
                                  }
                                />
                              </TableCell>
                              {mode === "edit" && !checkedItems[head.id] ? (
                                <TableCell sx={{ textAlign: "center" }}>
                                  <EditOutlinedIcon
                                    sx={{ mx: 1, cursor: "pointer" }}
                                    onClick={() => handleOrderEdit(index, head)}
                                  />
                                  <DeleteForeverOutlinedIcon
                                    sx={{ mx: 1, cursor: "pointer" }}
                                    onClick={() => handleOrderDelete(index)}
                                  />
                                </TableCell>
                              ) : null}
                            </TableRow>
                          ))
                        : orderDetails.map((detail, index) => {
                            const relatedBreakups = breakupDetail.filter(
                              (breakup) => breakup.orderId === detail.id
                            );
                            const totalPrice = relatedBreakups.reduce(
                              (acc, breakup) => acc + parseFloat(breakup.price),
                              0
                            );
                            const breakupString = relatedBreakups
                              .map((breakup) => breakup.price)
                              .join(" + ");

                            return (
                              <TableRow key={index}>
                                <TableCell sx={{ textAlign: "center" }}>
                                  {detail.id}
                                </TableCell>
                                <TableCell sx={{ textAlign: "center" }}>
                                  {detail.particular}
                                </TableCell>
                                <TableCell sx={{ textAlign: "center" }}>
                                  {detail.color}
                                </TableCell>
                                <TableCell sx={{ textAlign: "center" }}>
                                  {detail.lining}
                                </TableCell>
                                <TableCell sx={{ textAlign: "center" }}>
                                  {detail.qty}
                                </TableCell>
                                <TableCell sx={{ textAlign: "center" }}>
                                  {detail.deltype}
                                </TableCell>
                                <TableCell sx={{ textAlign: "center" }}>
                                  {detail.master}
                                </TableCell>
                                <TableCell sx={{ textAlign: "center" }}>
                                  {detail.tailor}
                                </TableCell>
                                <TableCell sx={{ textAlign: "center" }}>
                                  {detail.attachment ? (
                                    <AttachCopy detail={detail.attachment} />
                                  ) : (
                                    "No image"
                                  )}
                                </TableCell>
                                <TableCell sx={{ textAlign: "center" }}>
                                  {detail.breakupDetails.map((breakup, idx) => (
                                    <React.Fragment key={idx}>
                                      {idx > 0 && " + "} {breakup.price}
                                    </React.Fragment>
                                  ))}
                                  <Popup
                                    historyDetail={detail.breakupDetails.map(
                                      (breakup, idx) => (
                                        <React.Fragment key={idx}>
                                          {idx > 0 && " / "}
                                          {breakup.title}: {breakup.price}
                                        </React.Fragment>
                                      )
                                    )}
                                  />
                                </TableCell>
                                <TableCell sx={{ textAlign: "center" }}>
                                  {detail.amount}
                                </TableCell>
                                {mode === "print" ? null : (
                                  <TableCell sx={{ textAlign: "center" }}>
                                    <EditOutlinedIcon
                                      sx={{ mx: 1, cursor: "pointer" }}
                                      onClick={() =>
                                        handleOrderEdit(index, detail)
                                      }
                                    />
                                    <DeleteForeverOutlinedIcon
                                      sx={{ mx: 1, cursor: "pointer" }}
                                      onClick={() => handleOrderDelete(index)}
                                    />
                                  </TableCell>
                                )}
                              </TableRow>
                            );
                          })}
                    </TableBody>
                  </Table>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Box>

          <Grid container>
            <Grid
              item
              xs={12}
              sm={6}
              mt={2}
              container
              justifyContent="flex-end"
            ></Grid>
            <Grid
              item
              xs={12}
              sm={6}
              mt={2}
              container
              justifyContent="flex-end"
            >
              {mode === "orderhistory" ||
              mode === "view" ||
              mode === "edit" ||
              mode === "print" ? null : (
                <>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DateTimePicker
                      label="Order Date"
                      value={mainFormik.values.orddate}
                      onChange={(date) =>
                        mainFormik.setFieldValue("orddate", date)
                      }
                      name="order_date"
                      id="orddate"
                      maxDate={moment().endOf("day")}
                      minDate={
                        latestOrderDate === null || !latestOrderDate
                          ? moment().subtract(100, "years")
                          : moment(latestOrderDate)
                      }
                      // minDate={latestOrderDate}
                      format="DD/MM/YYYY HH:mm"
                      disabled={isReadOnly}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          name="orddate"
                          id="orddate"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>

                  <p
                    style={{
                      fontSize: 12,
                      color: "red",
                      paddingTop: 12,
                      display: "block",
                    }}
                  >
                    {mainFormik.touched.orddate && mainFormik.errors.orddate}
                  </p>
                </>
              )}
            </Grid>
          </Grid>
          <Box mt={2}>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" component="h2" sx={{ mt: 2 }}>
                  Blouses
                </Typography>
              </Grid>
              {mode === "edit" || mode === "view" ? (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button
                    variant="contained"
                    size="medium"
                    sx={{ mx: 1, my: 1 }}
                    className={classes.btn}
                    onClick={workPrint}
                  >
                    <LocalPrintshopOutlinedIcon />
                    Internal Copy
                  </Button>
                </Grid>
              ) : null}
            </Grid>
            <TableContainer component={Paper}>
              <Table
                aria-label="simple table"
                sx={{ border: "1px solid #efefef" }}
              >
                <TableBody>
                  <TableRow sx={{ border: "1px solid #efefef" }}>
                    {size1.map((title) => (
                      <TableCell component="th" scope="row">
                        <TextField
                          sx={{
                            width: {
                              xs: "50px",
                              sm: "100%",
                            },
                          }}
                          label={title.title}
                          variant="outlined"
                          id={title.id}
                          name={title.name}
                          placeholder={title.title}
                          onChange={mainFormik.handleChange}
                          onBlur={mainFormik?.handleBlur}
                          error={
                            mainFormik?.touched?.[title.name] &&
                            Boolean(mainFormik?.errors?.[title.name])
                          }
                          helperText={
                            mainFormik?.touched?.[title.name] &&
                            mainFormik?.errors?.[title.name]
                          }
                          value={mainFormik.values[title.name]}
                          InputProps={{ readOnly: isReadOnly }}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
                <TableBody>
                  <TableRow sx={{ border: "1px solid #efefef" }}>
                    {size2.map((title) => (
                      <TableCell component="th" scope="row">
                        <TextField
                          sx={{
                            width: {
                              xs: "50px",
                              sm: "100%",
                            },
                          }}
                          label={title.title}
                          variant="outlined"
                          id={title.id}
                          name={title.name}
                          placeholder={title.title}
                          onChange={mainFormik.handleChange}
                          onBlur={mainFormik?.handleBlur}
                          error={
                            mainFormik?.touched?.[title.name] &&
                            Boolean(mainFormik?.errors?.[title.name])
                          }
                          helperText={
                            mainFormik?.touched?.[title.name] &&
                            mainFormik?.errors?.[title.name]
                          }
                          value={mainFormik.values[title.name]}
                          InputProps={{ readOnly: isReadOnly }}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Typography variant="subtitle2" component="h2" sx={{ mt: 2 }}>
              Set
            </Typography>
            <TableContainer component={Paper}>
              <Table
                aria-label="simple table"
                sx={{ border: "1px solid #efefef" }}
              >
                <TableBody>
                  <TableRow sx={{ border: "1px solid #efefef" }}>
                    {size3.map((title) => (
                      <TableCell component="th" scope="row">
                        <TextField
                          sx={{
                            width: {
                              xs: "50px",
                              sm: "100%",
                            },
                          }}
                          label={title.title}
                          variant="outlined"
                          id={title.id}
                          name={title.name}
                          placeholder={title.title}
                          onChange={mainFormik.handleChange}
                          onBlur={mainFormik?.handleBlur}
                          error={
                            mainFormik?.touched?.[title.name] &&
                            Boolean(mainFormik?.errors?.[title.name])
                          }
                          helperText={
                            mainFormik?.touched?.[title.name] &&
                            mainFormik?.errors?.[title.name]
                          }
                          value={mainFormik.values[title.name]}
                          InputProps={{ readOnly: isReadOnly }}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
                <TableBody>
                  <TableRow sx={{ border: "1px solid #efefef" }}>
                    {size4.map((title) => (
                      <TableCell component="th" scope="row">
                        <TextField
                          sx={{
                            width: {
                              xs: "50px",
                              sm: "100%",
                            },
                          }}
                          label={title.title}
                          variant="outlined"
                          id={title.id}
                          name={title.name}
                          placeholder={title.title}
                          onChange={mainFormik.handleChange}
                          onBlur={mainFormik?.handleBlur}
                          error={
                            mainFormik?.touched?.[title.name] &&
                            Boolean(mainFormik?.errors?.[title.name])
                          }
                          helperText={
                            mainFormik?.touched?.[title.name] &&
                            mainFormik?.errors?.[title.name]
                          }
                          value={mainFormik.values[title.name]}
                          InputProps={{ readOnly: isReadOnly }}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <TableContainer sx={{ mt: 2 }} component={Paper}>
              <Table
                aria-label="simple table"
                sx={{ border: "1px solid #efefef" }}
              >
                <TableBody>
                  <TableRow sx={{ border: "1px solid #efefef" }}>
                    {size5.map((title) => (
                      <TableCell component="th" scope="row">
                        <TextField
                          sx={{
                            width: {
                              xs: "50px",
                              sm: "100%",
                            },
                          }}
                          label={title.title}
                          variant="outlined"
                          id={title.id}
                          name={title.name}
                          placeholder={title.title}
                          onChange={mainFormik.handleChange}
                          onBlur={mainFormik?.handleBlur}
                          error={
                            mainFormik?.touched?.[title.name] &&
                            Boolean(mainFormik?.errors?.[title.name])
                          }
                          helperText={
                            mainFormik?.touched?.[title.name] &&
                            mainFormik?.errors?.[title.name]
                          }
                          value={mainFormik.values[title.name]}
                          InputProps={{ readOnly: isReadOnly }}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <Box sx={{ mt: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} lg={4}>
                <TextField
                  id="outlined-multiline-flexible"
                  label="Notes"
                  fullWidth
                  multiline
                  maxRows={10}
                  name="notes"
                  onChange={mainFormik.handleChange}
                  onBlur={mainFormik?.handleBlur}
                  error={
                    mainFormik?.touched?.notes &&
                    Boolean(mainFormik?.errors?.notes)
                  }
                  helperText={
                    mainFormik?.touched?.notes && mainFormik?.errors?.notes
                  }
                  value={mainFormik.values.notes}
                  InputProps={{ readOnly: isReadOnly }}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <TextField
                  id="outlined-multiline-flexible"
                  label="Lining"
                  fullWidth
                  name="lining"
                  onChange={mainFormik.handleChange}
                  onBlur={mainFormik?.handleBlur}
                  error={
                    mainFormik?.touched?.lining &&
                    Boolean(mainFormik?.errors?.lining)
                  }
                  helperText={
                    mainFormik?.touched?.lining && mainFormik?.errors?.lining
                  }
                  value={mainFormik.values.lining}
                  InputProps={{ readOnly: isReadOnly }}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <TextField
                  id="outlined-multiline-flexible"
                  label="Quantity"
                  fullWidth
                  name="quantity"
                  onChange={mainFormik.handleChange}
                  onBlur={mainFormik?.handleBlur}
                  error={
                    mainFormik?.touched?.quantity &&
                    Boolean(mainFormik?.errors?.quantity)
                  }
                  helperText={
                    mainFormik?.touched?.quantity &&
                    mainFormik?.errors?.quantity
                  }
                  value={mainFormik.values.quantity}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <TextField
                  id="outlined-multiline-flexible"
                  label="Bill Amount"
                  fullWidth
                  name="bill_amount"
                  onChange={mainFormik.handleChange}
                  onBlur={mainFormik?.handleBlur}
                  error={
                    mainFormik?.touched?.bill_amount &&
                    Boolean(mainFormik?.errors?.bill_amount)
                  }
                  helperText={
                    mainFormik?.touched?.bill_amount &&
                    mainFormik?.errors?.bill_amount
                  }
                  value={mainFormik.values.bill_amount}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              {mode === "orderhistory" ||
              mode === "view" ||
              mode === "edit" ? null : (
                <>
                  <Grid item xs={12} sm={6} lg={4}>
                    <TextField
                      id="outlined-multiline-flexible"
                      label={mode === "edit" ? "Payment" : "Advance"}
                      fullWidth
                      name="advance"
                      onChange={handleAdvanceChange}
                      onBlur={mainFormik.handleBlur("advance")}
                      error={
                        mainFormik.touched.advance &&
                        Boolean(mainFormik.errors.advance)
                      }
                      helperText={
                        mainFormik.touched.advance && mainFormik.errors.advance
                      }
                      value={mainFormik.values.advance}
                      InputProps={{ readOnly: isReadOnly }}
                    />
                  </Grid>
                  {mode === "view" ? null : (
                    <>
                      {mainFormik?.values?.advance > 0 ? (
                        <>
                          <Grid item xs={12} sm={6} lg={4}>
                            <FormControl fullWidth>
                              <InputLabel id="payment_mode">
                                Payment Mode
                              </InputLabel>
                              <Select
                                labelId="payment_mode"
                                id="payment_mode"
                                name="payment_mode"
                                label="Payment Mode"
                                value={mainFormik.values.payment_mode}
                                onChange={mainFormik.handleChange}
                                onBlur={mainFormik.handleBlur}
                                error={
                                  mainFormik.touched.payment_mode &&
                                  Boolean(mainFormik.errors.payment_mode)
                                }
                                disabled={isReadOnly}
                              >
                                <MenuItem value="cash">Cash</MenuItem>
                                <MenuItem value="bank">Bank</MenuItem>
                              </Select>
                              <FormHelperText>
                                {mainFormik.touched.payment_mode &&
                                  mainFormik.errors.payment_mode}
                              </FormHelperText>
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} sm={6} lg={4}>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                              }}
                            >
                              <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                  label="Payment Date"
                                  name="payment_date"
                                  id="payment_date"
                                  value={mainFormik.values.payment_date}
                                  onChange={(date) =>
                                    mainFormik.setFieldValue(
                                      "payment_date",
                                      date
                                    )
                                  }
                                  minDate={
                                    mainFormik.values.orddate
                                      ? moment(mainFormik.values.orddate)
                                      : undefined
                                  } // Ensure it's a valid date
                                  maxDate={moment().endOf("day")}
                                  format="DD/MM/YYYY"
                                  disabled={isReadOnly}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      fullWidth
                                      name="payment_date"
                                      onBlur={mainFormik.handleBlur}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                              <p
                                style={{
                                  fontSize: 12,
                                  color: "red",
                                  paddingTop: 5,
                                  margin: 0,
                                }}
                              >
                                {mainFormik.touched.payment_date &&
                                  mainFormik.errors.payment_date}
                              </p>
                            </Box>
                          </Grid>
                        </>
                      ) : null}
                      {/* {mode !== "edit" && (
                        <Grid item xs={12} sm={6} lg={4}>
                          <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                              label="1st Delivery Date"
                              value={mainFormik.values.delivery_date}
                              onChange={(date) =>
                                mainFormik.setFieldValue("delivery_date", date)
                              }
                              minDate={moment(
                                mainFormik?.values?.orddate
                              ).startOf("day")} // Set minDate to orddate
                              format="DD/MM/YYYY"
                              disabled={isReadOnly}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  name="delivery_date"
                                  onBlur={mainFormik.handleBlur}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              )}
                            />
                          </LocalizationProvider>
                          <p
                            style={{
                              fontSize: 12,
                              color: "red",
                              paddingTop: 12,
                            }}
                          >
                            {mainFormik.touched.delivery_date &&
                              mainFormik.errors.delivery_date}
                          </p>
                        </Grid>
                      )} */}
                    </>
                  )}
                </>
              )}
              {(mode === "edit" && latestOrder?.due_amount !== 0) ||
              (mode === "edit" &&
                parseFloat(
                  (mainFormik?.values?.bill_amount || 0) -
                    totalPayments -
                    totalDiscounts -
                    (mainFormik?.values?.advance || 0) -
                    (mainFormik?.values?.discount || 0)
                ) !== 0) ||
              (mode === "edit" &&
                parseFloat(
                  (mainFormik?.values?.bill_amount || 0) -
                    totalPayments -
                    totalDiscounts -
                    (mainFormik?.values?.advance || 0) -
                    (mainFormik?.values?.discount || 0)
                ) === 0) ? (
                mode === "orderhistory" || mode === "view" ? null : (
                  <>
                    <Grid item xs={12} sm={6} lg={4}>
                      <TextField
                        id="outlined-multiline-flexible"
                        label={mode === "edit" ? "Payment" : "Advance"}
                        fullWidth
                        name="advance"
                        onChange={handleAdvanceChange}
                        onBlur={mainFormik.handleBlur("advance")}
                        error={
                          mainFormik.touched.advance &&
                          Boolean(mainFormik.errors.advance)
                        }
                        helperText={
                          mainFormik.touched.advance &&
                          mainFormik.errors.advance
                        }
                        value={mainFormik.values.advance}
                        InputProps={{ readOnly: isReadOnly }}
                      />
                    </Grid>
                    {mode === "view" ? null : (
                      <>
                        {mainFormik?.values?.advance > 0 && (
                          <>
                            <Grid item xs={12} sm={6} lg={4}>
                              <FormControl fullWidth>
                                <InputLabel id="payment_mode">
                                  Payment Mode
                                </InputLabel>
                                <Select
                                  labelId="payment_mode"
                                  id="payment_mode"
                                  name="payment_mode"
                                  label="Payment Mode"
                                  value={mainFormik.values.payment_mode}
                                  onChange={mainFormik.handleChange}
                                  onBlur={mainFormik.handleBlur}
                                  error={
                                    mainFormik.touched.payment_mode &&
                                    Boolean(mainFormik.errors.payment_mode)
                                  }
                                  disabled={isReadOnly}
                                >
                                  <MenuItem value="cash">Cash</MenuItem>
                                  <MenuItem value="bank">Bank</MenuItem>
                                </Select>
                                <FormHelperText>
                                  {mainFormik.touched.payment_mode &&
                                    mainFormik.errors.payment_mode}
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={4}>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "100%",
                                }}
                              >
                                <LocalizationProvider
                                  dateAdapter={AdapterMoment}
                                >
                                  <DatePicker
                                    label="Payment Date"
                                    name="payment_date"
                                    id="payment_date"
                                    value={moment(
                                      mainFormik.values.payment_date
                                    )}
                                    onChange={(date) =>
                                      mainFormik.setFieldValue(
                                        "payment_date",
                                        date
                                      )
                                    }
                                    minDate={
                                      mode === "edit"
                                        ? moment(
                                            latestOrder?.payment_date ||
                                              latestOrder?.order_date
                                          ).startOf("day")
                                        : moment(
                                            mainFormik.values.orddate
                                          ).startOf("day")
                                    }
                                    maxDate={moment().endOf("day")}
                                    format="DD/MM/YYYY"
                                    disabled={isReadOnly}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        fullWidth
                                        name="payment_date"
                                        onBlur={mainFormik.handleBlur}
                                        InputLabelProps={{ shrink: true }}
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                                <p
                                  style={{
                                    fontSize: 12,
                                    color: "red",
                                    paddingTop: 5,
                                    margin: 0,
                                  }}
                                >
                                  {mainFormik.touched.payment_date &&
                                    mainFormik.errors.payment_date}
                                </p>
                              </Box>
                              {/* {2console.log(
                                "date1",
                                moment(mainFormik.values.payment_date).format(
                                  "DD-MM-YYYY HH-mm"
                                ),

                                moment(latestOrder?.payment_date).format(
                                  "DD-MM-YYYY HH-mm"
                                ),
                                moment(latestOrder?.order_date).format(
                                  "DD-MM-YYYY HH-mm"
                                )
                              )} */}
                            </Grid>
                          </>
                        )}
                      </>
                    )}
                  </>
                )
              ) : null}

              {mode !== "view" && mode !== "orderhistory" && (
                <Grid item xs={12} sm={6} lg={4}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        label="1st Delivery Date"
                        value={
                          mode === "edit"
                            ? latestOrder?.delivery_date
                              ? moment(latestOrder.delivery_date)
                              : null
                            : mainFormik.values.delivery_date
                            ? moment(mainFormik.values.delivery_date)
                            : null
                        }
                        onChange={
                          (date) =>
                            mainFormik.setFieldValue(
                              "delivery_date",
                              moment(date)
                            ) // Save as a native Date object if required
                        }
                        minDate={
                          mode === "edit"
                            ? latestOrder?.order_date
                              ? moment(latestOrder.order_date).startOf("day")
                              : null
                            : mainFormik.values?.orddate
                            ? moment(mainFormik.values.orddate).startOf("day")
                            : null
                        }
                        format="DD/MM/YYYY"
                        disabled={isReadOnly}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            name="delivery_date"
                            onBlur={mainFormik.handleBlur}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                    <p
                      style={{
                        fontSize: 12,
                        color: "red",
                        paddingTop: 5,
                        margin: 0,
                      }}
                    >
                      {mainFormik.touched.delivery_date &&
                        mainFormik.errors.delivery_date}
                    </p>
                  </Box>
                </Grid>
              )}

              {mode === "orderhistory" || mode === "view"
                ? null
                : shouldShowDatePicker && (
                    <Grid item xs={12} sm={6} lg={4}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DatePicker
                            label="2nd Delivery Date"
                            value={
                              mode === "edit"
                                ? moment(latestOrder?.second_delivery_date)
                                : mainFormik.values.second_delivery_date
                            }
                            onChange={(date) =>
                              mainFormik.setFieldValue(
                                "second_delivery_date",
                                date
                              )
                            }
                            minDate={moment(mainFormik?.values?.delivery_date)
                              .add(1, "day")
                              .startOf("day")}
                            format="DD/MM/YYYY"
                            disabled={isReadOnly}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                name="second_delivery_date"
                                onBlur={mainFormik.handleBlur}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                        <p
                          style={{
                            fontSize: 12,
                            color: "red",
                            paddingTop: 5,
                            margin: 0,
                          }}
                        >
                          {mainFormik.touched.second_delivery_date &&
                            mainFormik.errors.second_delivery_date}
                        </p>
                      </Box>
                    </Grid>
                  )}
              {/* {1console.log(
                latestOrder?.is_second_delivery_date_req,
                editSecondDeliveryDate,
                shouldShowDatePicker,
                shouldShowDatePicker
              )} */}
              {(mode === "edit" && latestOrder?.due_amount !== 0) ||
              (mode === "edit" &&
                parseFloat(
                  (mainFormik?.values?.bill_amount || 0) -
                    totalPayments -
                    totalDiscounts -
                    (mainFormik?.values?.advance || 0) -
                    (mainFormik?.values?.discount || 0)
                ) !== 0) ||
              (mode === "edit" &&
                parseFloat(
                  (mainFormik?.values?.bill_amount || 0) -
                    totalPayments -
                    totalDiscounts -
                    (mainFormik?.values?.advance || 0) -
                    (mainFormik?.values?.discount || 0)
                ) === 0) ? (
                <Grid item xs={12} sm={6} lg={4}>
                  <TextField
                    id="outlined-multiline-flexible"
                    label="Discount"
                    fullWidth
                    name="discount"
                    onChange={mainFormik.handleChange}
                    onBlur={mainFormik.handleBlur}
                    error={
                      mainFormik.touched.discount &&
                      Boolean(mainFormik.errors.discount)
                    }
                    helperText={
                      mainFormik.touched.discount && mainFormik.errors.discount
                    }
                    value={mainFormik.values.discount || 0}
                    InputProps={{ readOnly: isReadOnly }}
                  />
                </Grid>
              ) : null}
              {mode === "edit" ? (
                <Grid item xs={12} sm={6} lg={4}>
                  <FormControl fullWidth>
                    <InputLabel id="order_status">Order Status</InputLabel>
                    <Select
                      labelId="order_status"
                      id="order_status"
                      name="order_status"
                      label="Order Status"
                      value={mainFormik.values.order_status}
                      onChange={mainFormik.handleChange}
                      onBlur={mainFormik.handleBlur}
                      error={
                        mainFormik.touched.order_status &&
                        Boolean(mainFormik.errors.order_status)
                      }
                      helperText={
                        mainFormik.touched.order_status &&
                        mainFormik.errors.order_status
                      }
                      InputProps={{ readOnly: isReadOnly }}
                    >
                      <MenuItem value="pending">Pending</MenuItem>
                      <MenuItem value="inprogress">In-Progress</MenuItem>
                      <MenuItem value="complete">Completed</MenuItem>
                      {allChecked && (
                        <MenuItem value="delivered">Delivered</MenuItem>
                      )}
                      <MenuItem value="cancelled">Cancelled</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              ) : null}
              {mainFormik.values.order_status === "cancelled" && (
                <Grid item xs={12} sm={6} lg={4}>
                  <FormControl fullWidth>
                    <TextField
                      id="cancel_reason"
                      name="cancel_reason"
                      label="Reason"
                      value={mainFormik.values.cancel_reason}
                      onChange={mainFormik.handleChange}
                      onBlur={mainFormik.handleBlur}
                      error={
                        mainFormik.touched.cancel_reason &&
                        Boolean(mainFormik.errors.cancel_reason)
                      }
                      helperText={
                        mainFormik.touched.cancel_reason &&
                        mainFormik.errors.cancel_reason
                      }
                      InputProps={{ readOnly: isReadOnly }}
                    />
                  </FormControl>
                </Grid>
              )}

              {mode === "orderhistory" && mode !== "edit" && (
                <Grid item xs={12} sm={6} lg={4}>
                  <TextField
                    id="outlined-multiline-flexible"
                    label="Discount"
                    fullWidth
                    name="discount"
                    onChange={mainFormik.handleChange}
                    onBlur={mainFormik.handleBlur}
                    error={
                      mainFormik.touched.discount &&
                      Boolean(mainFormik.errors.discount)
                    }
                    helperText={
                      mainFormik.touched.discount && mainFormik.errors.discount
                    }
                    value={mainFormik.values.discount || 0}
                    InputProps={{ readOnly: isReadOnly }}
                  />
                </Grid>
              )}
              {mode === "edit" ||
              mode === "orderhistory" ||
              mode === "print" ||
              mode === "view" ? null : (
                <Grid item xs={12} sm={6} lg={4}>
                  <TextField
                    id="outlined-multiline-flexible"
                    label="Discount"
                    fullWidth
                    name="discount"
                    onChange={mainFormik.handleChange}
                    onBlur={mainFormik.handleBlur}
                    error={
                      mainFormik.touched.discount &&
                      Boolean(mainFormik.errors.discount)
                    }
                    helperText={
                      mainFormik.touched.discount && mainFormik.errors.discount
                    }
                    value={mainFormik.values.discount || 0}
                    InputProps={{ readOnly: isReadOnly }}
                  />
                </Grid>
              )}
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={6} lg={4}>
                {mode === "view" ? (
                  <Typography variant="subtitle1" component="span">
                    <strong>Due Amount: </strong>₹ {latestOrder?.due_amount}
                    <Popup
                      historyDetail={parsedPaymentDetails
                        .filter(
                          (detail) =>
                            !(detail.amount === 0 && detail.discount === 0)
                        ) // Exclude entries where both amount and discount are zero
                        .map((detail, idx) => (
                          <React.Fragment key={idx}>
                            {idx > 0 && <br />}{" "}
                            {/* Add a line break for every detail after the first */}
                            {/* Determine the date to display */}
                            {detail.payment_date === null ||
                            detail.payment_date === "null"
                              ? "-"
                              : new Date(
                                  detail.payment_date
                                ).toLocaleDateString("en-GB", {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                })}
                            {detail.amount !== 0 && (
                              <>
                                <span> / Payment: ₹{detail.amount} / </span>
                              </>
                            )}
                            {detail.payment_mode}
                            {detail.discount !== 0 && (
                              <>
                                <span> / Discount: ₹{detail.discount}</span>
                              </>
                            )}
                            {idx !== parsedPaymentDetails.length - 1 && <br />}
                          </React.Fragment>
                        ))}
                    />
                  </Typography>
                ) : (
                  <Typography variant="subtitle1" component="span">
                    <strong>Due Amount: </strong>₹
                    {mode === "edit"
                      ? (() => {
                          const calculatedDueAmount = parseFloat(
                            latestOrder?.due_amount -
                              mainFormik?.values?.advance -
                              mainFormik?.values?.discount
                          );

                          // if (calculatedDueAmount < 0) {
                          //   dispatch(
                          //     showSnackbar({
                          //       message: "Due amount cannot be negative.",
                          //       autoHideDuration: 3000,
                          //       anchorOrigin: {
                          //         vertical: "top",
                          //         horizontal: "right",
                          //       },
                          //       variant: "warning",
                          //     })
                          //   );
                          //   mainFormik.setFieldValue("advance", 0);
                          //   mainFormik.setFieldValue("discount", 0);
                          // }
                          const sample = parseFloat(
                            (mainFormik?.values?.bill_amount || 0) -
                              totalPayments -
                              totalDiscounts -
                              (mainFormik?.values?.advance || 0) -
                              (mainFormik?.values?.discount || 0)
                          );
                          // 2console.log("edit", sample);
                          // 2console.log(
                          //   "paid",
                          //   parsedPaymentDetails.map((data) => data?.amount)
                          // );
                          return sample;
                        })()
                      : mode === "orderhistory"
                      ? selectedHistoryData?.due_amount
                      : mainFormik.values.due_amount >= 0
                      ? resultCreate
                      : resultCreate}
                    {/* {mode === "edit"
                      ? mainFormik?.setFieldValue(
                          "due_amount",
                          latestOrder?.due_amount
                        )
                      : null} */}
                    {mode === "edit" && (
                      <Popup
                        historyDetail={parsedPaymentDetails
                          .filter(
                            (detail) =>
                              !(detail.amount === 0 && detail.discount === 0)
                          ) // Exclude entries where both amount and discount are zero
                          .map((detail, idx) => (
                            <React.Fragment key={idx}>
                              {idx > 0 && <br />}{" "}
                              {/* Add a line break for every detail after the first */}
                              {/* Determine the date to display */}
                              {detail.payment_date === null ||
                              detail.payment_date === "null"
                                ? "-"
                                : new Date(
                                    detail.payment_date
                                  ).toLocaleDateString("en-GB", {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                  })}
                              {detail.amount !== 0 && (
                                <>
                                  <span> / Payment: ₹{detail.amount} / </span>
                                </>
                              )}
                              {detail.payment_mode}
                              {detail.discount !== 0 && (
                                <>
                                  <span> / Discount: ₹{detail.discount}</span>
                                </>
                              )}
                              {idx !== parsedPaymentDetails.length - 1 && (
                                <br />
                              )}
                            </React.Fragment>
                          ))}
                      />
                    )}
                  </Typography>
                )}
              </Grid>
              {/* edit */}

              {mode === "edit" ? (
                <Grid item xs={12} sm={6} lg={4}>
                  <Typography variant="subtitle1" component="span">
                    <strong>Payment / Discount: </strong>₹{totalPaidAmount}
                    {/* {1console.log(
                      "ss",
                      mainFormik?.values?.bill_amount >= totalPaidAmount
                    )} */}
                  </Typography>
                </Grid>
              ) : null}

              {/* edit */}
              {mode === "view" ? (
                <Grid item xs={12} sm={6} lg={4}>
                  <Typography variant="subtitle1" component="span">
                    <strong>Payment / Discount: </strong>₹{totalPaidAmount}
                  </Typography>
                </Grid>
              ) : null}
            </Grid>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: { xs: "flex-start", sm: "flex-end" },
              mt: 2,
            }}
          >
            <>
              {mode === "print" || mode === "view" ? (
                <>
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      mb: 1,
                      mx: 0.5,
                      width: "auto",
                    }}
                    className={classes.btn}
                    startIcon={<LocalPrintshopOutlinedIcon />}
                    onClick={customerPrint}
                  >
                    Customer Order Copy
                  </Button>
                </>
              ) : null}
              {mode === "print" ? (
                <>
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      mb: 1,
                      mx: 0.5,
                      width: "auto",
                    }}
                    className={classes.btn}
                    startIcon={<LocalPrintshopOutlinedIcon />}
                    onClick={workPrint}
                  >
                    Internal Copy
                  </Button>
                </>
              ) : null}
              {mode === "view" || mode === "print" ? (
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    mb: 1,
                    mx: 0.5,
                    width: "auto",
                  }}
                  className={classes.btn}
                  startIcon={<CloseOutlinedIcon />}
                  onClick={handleClose}
                >
                  Close
                </Button>
              ) : null}
              {mode === "orderhistory" ? (
                <Button
                  variant="contained"
                  size="medium"
                  className={classes.btn}
                  sx={{}}
                  startIcon={<DoneOutlinedIcon />}
                  onClick={handleNewOrder}
                >
                  Apply to New Order
                </Button>
              ) : null}
              {mode === "view" ||
              mode === "orderhistory" ||
              mode === "print" ? null : (
                <>
                  {mainFormik.values.order_status === "complete" && (
                    <Whatsapp
                      bill={latestOrder?.bill_amount}
                      due={latestOrder?.due_amount}
                      name={latestOrder?.customer?.user?.name}
                      billId={latestOrder?.uuid}
                    />
                  )}
                  <Button
                    variant="contained"
                    size="medium"
                    className={classes.btn}
                    sx={{}}
                    startIcon={<DoneOutlinedIcon />}
                    onClick={mainFormik.handleSubmit}
                  >
                    Submit
                  </Button>
                </>
              )}
            </>
          </Box>
        </Box>
      </Modal>
      <CustomerCreate
        open={createCustomer}
        setOpen={setCreateCustomer}
        mobileNumber={formik?.values?.mobileNumber}
        setCustomerId={setCustomerId}
        customerId={customerId}
        setCustomerData={setCustomerData}
        customerData={customerData}
        mode="create"
        setMode={() => setMode("create")}
        allMobileNumber={allMobileNumber}
        handleMobileNumberChange={handleMobileNumberChange}
      />
      <OrderHistory
        setHisopen={setHisopen}
        hisopen={hisopen}
        handlehisClose={handlehisClose}
        setMode={setMode}
        mode={mode}
        latestOrder={latestOrder}
        customerData={customerData}
        customerNameOptions={customerNameOptions}
        orderData={orderData}
        setOrderData={setOrderData}
        setLatestOrder={setLatestOrder}
        orderHistoryList={orderHistoryList}
      />
    </>
  );
};
export default Create;
