// WhatsAppShare.js
import React from "react";
import { Button } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const useStyles = makeStyles((theme) => ({
  btn: {
    backgroundColor: "#f06666 !important", // Adjust background color as needed
    color: theme.palette.primary.contrastText,
  },
}));

const WhatsAppShare = ({ bill, name, due, billId }) => {
  const billUrl = `${window.location.origin}/whatsappprint/${billId}`;

  const message = `Hello! \n
    We are from\n
    *Tailoring Automation System*\n
    Kolathur Branch.\n
    *${name}* our outfit is now ready for pickup.\n
   Kindly note, we accept only [Cash, G-Pay, and PhonePe] for payments.Your total bill is  ₹${bill}/-*,\n
    *and the balance due is ₹${due}/-*.\n
    *Pickup Timings :Mon to Fri 11am to 7pm  (or) Saturday 11am to 7.30pm*\n
    *To avoid any waiting time, we recommend booking your visit in advance. Thank you for choosing us, and we hope to see you soon!\n
    ${billUrl}`;

  const handleShare = () => {
    const shareUrl = `https://wa.me/?text=${encodeURIComponent(message)}`;
    window.open(shareUrl, "_blank");
  };

  const classes = useStyles();

  return (
    <div>
      <Button
        variant="contained"
        size="medium"
        sx={{
          mx: 0.5,
        }}
        className={classes.btn}
        startIcon={<WhatsAppIcon />}
        onClick={handleShare}
      >
        Send via WhatsApp
      </Button>
    </div>
  );
};

export default WhatsAppShare;
